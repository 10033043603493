import {gql} from '@apollo/client'

export const SAVE_ANSWER = gql`
    mutation saveAnswer($userId: UUID!, $category: String!, $answers: [String]!){
      saveAnswer(input: {userId: $userId, answers: $answers, category: $category}){
        clientMutationId
      }
    }
`

export const FINISH_EXAM = gql`
    mutation finishExam($userId: UUID!){
      finishExam(input: {userId: $userId}){
        clientMutationId
      }
    }
`