import React, {FC, useEffect, useState} from 'react'
import {Controller, useFormContext} from "react-hook-form"
import {FormControl, FormHelperText} from "@material-ui/core";
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {AnswersType} from "../../examComponents/examUtils/exam.types";
import {useButtonsControllerStyles} from "../buttonsController/ButtonsController.styles";
import {useMutation} from "@apollo/client";
import {SAVE_ANSWER} from "../../examComponents/examQuestionCard/ExamQuestionCard.mutation";
import {useDispatch} from "react-redux";
import {addSavingAnswer, removeSavingAnswer} from "../../../store/savingExamAnswer/savingExamAnswer.slice";

interface MultiselectButtonsControllerProps {
  name: string,
  answers: AnswersType[],
  horizontal: boolean,
  userId: string
}

const MultiselectButtonsController: FC<MultiselectButtonsControllerProps> = ({userId,name, answers, horizontal}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const form = useFormContext()
  const classes = useButtonsControllerStyles()
  const dispatch = useDispatch()
  const [saveAnswer, {loading}] = useMutation(SAVE_ANSWER)

  useEffect(() => {
    if(loading) {
      dispatch(addSavingAnswer(name))
    } else {
      dispatch(removeSavingAnswer(name))
    }
  },[loading])

  const sendAnswer = async (value: string[], code: string) => {
    const isValidate = !(value.length === 1 && ["+1", "+2" , "+3", "+4"].includes(value[0]))

    if (isValidate) {
      try {
        await saveAnswer({
          variables: {
            answers: value,
            category: code,
            userId
          }
        })
      } catch (e) {
        console.error(e)
      }
    } else {
      setErrorMessage("Dla tej odpowiedzi wymagane jest zaznaznaczenie drugiej z przedziału: [0, 1, 2, 3, 4]")
    }
  }

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={[]}
      rules={{
        required: true,
        validate: {
            catEValidation: (value) => !(value.length === 1 && ["+1", "+2" , "+3", "+4"].includes(value[0])) || "Dla tej odpowiedzi wymagane jest zaznaznaczenie drugiej z przedziału: [1, 2, 3, 4]",
        }
    }}
      render={(
        {
          field: {
            onChange,
            value
          },
          fieldState: {
            error,
            invalid
          }
        }) => (
        <FormControl
          component="fieldset"
          error={invalid}
        >
          <>
            <ToggleButtonGroup
              id={name}
              color="secondary"
              value={value}
              onChange={(e, value) => {
                if(!loading) {
                  setErrorMessage("")

                  //max dwie wartości w tablicy, jeżeli więcej to czyścimy tablicę
                  if (value.length > 2) {
                    onChange([])
                    sendAnswer([], name)
                  } else if (value.length > 1 && value.every((item: string) => item.charAt(0) === "+")) {
                    onChange([])
                    //może być tylko jedna wartość z value która nie ma "+" z przodu
                    sendAnswer([], name)
                  } else if (value.length > 1 && value.every((item: string) => Number(item) > 0 && item.charAt(0) !== "+")) {
                    onChange([])
                    sendAnswer([], name)
                    //8 może być tylko jako jedyna wartość
                  } else if (value.includes("8")) {
                    onChange(["8"])
                    sendAnswer(["8"], name)
                    //9 może być tylko jako jedyna wartość
                  } else if (value.includes("9")) {
                    onChange(["9"])
                    sendAnswer(["9"], name)
                  } else {
                    onChange(value)
                    sendAnswer(value, name)
                  }
                }
              }}
              className={error && classes.errorContainer}
              orientation={horizontal ? "horizontal" : "vertical"}
            >
              {answers.map(answer => (
                <ToggleButton
                  value={answer.label}
                  key={answer.description}
                  className={classes.button}
                  disabled={loading}
                >
                  {answer.label}
                  <br/>
                  {answer.description}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            { errorMessage
              ? (
                <FormHelperText  className={errorMessage && classes.errorText}>
                  {errorMessage}
                </FormHelperText>
              )
              : error && (
                <FormHelperText>
                  Odpowiedź jest wymagana
                </FormHelperText>
              )
            }

          </>
        </FormControl>
      )}
    />
  )
}
export default MultiselectButtonsController