import React, {FC, useState} from 'react'
import {Tooltip, IconButton} from "@material-ui/core";
import {Link, Assignment } from '@material-ui/icons';
import {useExamsManagementIconsStyles} from './ExamsManagementIcons.styles'
import { useHistory } from 'react-router';
import GlobalSuccessSnackbar from "../../formItems/globalSuccessSnackbar/GlobalSuccessSnackbar";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {Edit} from '@material-ui/icons';
import {ExamResultTypes} from "../examsResults/ExamsResult.types";

interface ExamsManagementIconsProps {
  userId: string
  finishedExam: Date | null
}

const ExamsManagementIcons: FC<ExamsManagementIconsProps> = ({userId,finishedExam}) => {
  const classes = useExamsManagementIconsStyles();
  const {push} = useHistory();
  const [isCopied, setIsCopied] = useState<boolean>(false)

  return (
    <div>
      {finishedExam &&
        <>
          <Tooltip title={"Karta wyników dla pracodawcy"}>
            <IconButton onClick={() => push(`/exam-result/${ExamResultTypes.GUARDIAN_RESULT}/${userId}`)}>
              <AssignmentTurnedInIcon className={classes.completed} />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Karta wyników dla pracownika"}>
            <IconButton onClick={() => push(`/exam-result/${ExamResultTypes.EXAMINED_PERSON_RESULTS}/${userId}`)}>
              <AssignmentIndIcon className={classes.completed} />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Edytuj badanie"}>
            <IconButton onClick={() => push(`/exam/${userId}`)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </>
      }

      {!finishedExam &&
        <>
          <Tooltip title={"Ręczne wypełnienie badania"}>
            <IconButton onClick={() => push(`/exam/${userId}`)}>
              <Assignment />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Generowanie linku do badania"}>
            <CopyToClipboard text={`${window.location.origin}/exam/${userId}`}>
              <IconButton onClick={() => setIsCopied(true)}>
                <Link />
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
        </>
      }

      <GlobalSuccessSnackbar
        isSuccess={isCopied}
        successMessage={"Skopiowano link do schowka"}
      />
    </div>
  )
}

export default ExamsManagementIcons