export enum RoutePath {
  BASE = "/",
  HOMEPAGE = "/games",
  ADMIN_HOMEPAGE = "/admin",
  LOGIN = "/login/:ref?",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password/:token/:newPassword?",
  USERS = "/users",
  REPORTS = "/reports",
  TENANTS = "/tenants",
  ERROR_404 = "/404",
  ERROR_403 = "/403",
  SERVER_ERROR = "/server-error/:code",
  FALLBACK = "/*",
  TESTED_PERSONS = '/tested-persons',
  EXAM = "/exam/:id",
  EXAM_RESULT = "/exam-result/:examResultType/:userId"
}
