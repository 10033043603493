import {FC, useEffect, useState} from 'react'
import BoxWithDivider from "../../common/boxWithDivider/BoxWithDivider.component";
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component";
import {FormProvider, useForm} from "react-hook-form";
import {Button, InputAdornment, Typography} from "@material-ui/core";
import {useAdditionalAnswerResultDescriptionStyles} from "./AdditionalAnswerResultDescription.styles";
import clsx from "clsx";
import {useMutation} from "@apollo/client";
import {ADD_ANSWER_DESCRIPTION} from "./AdditionalAnswerResultDescription.mutation";
import {ExamResultTypes} from "../../testedPersons/examsResults/ExamsResult.types";

interface AdditionalAnswerResultDescriptionProps {
  code: string;
  userId: string;
  description?: string;
  refetch: () => void;
  examResultType: ExamResultTypes;
}

const AdditionalAnswerResultDescription: FC<AdditionalAnswerResultDescriptionProps> = ({description, code, userId,refetch, examResultType}) => {
  const [editMode, setEditMode] = useState(false)
  const classes = useAdditionalAnswerResultDescriptionStyles()
  const form = useForm()
  const [addAnswerDescription, {loading}] = useMutation(ADD_ANSWER_DESCRIPTION)
  const value = form.watch("description")

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      await addAnswerDescription ({
        variables: {
          userId,
          category: code,
          description: values.description,
          employee: examResultType === ExamResultTypes.EXAMINED_PERSON_RESULTS
        }
      })

      await refetch()
      setEditMode(false)
    } catch (e) {
      console.error(e)
    }
  })

  useEffect(() => {
    form.setValue("description", description)
  },[editMode])

  return (
    <div className={clsx(!description && "print-hidden")}>
      <BoxWithDivider
        title={"Informacje dodatkowe"}
      >
        <div className={classes.descriptionBox}>
        { description ?
          (
            <>
              <Typography variant={"body2"} component={"p"}>
                {!editMode && description}
              </Typography>

              {!editMode &&
                <Button
                  onClick={() => setEditMode(true)}
                  variant={"contained"}
                  color={"secondary"}
                  disabled={loading}
                  className={clsx(classes.button, "print-hidden")}
                >
                  Edytuj
                </Button>
              }
            </>
          )
          :
          (
            <Typography variant={"body2"} component={"p"}>
              Brak informacji dodatkowych
            </Typography>
          )

        }
        </div>

        { (!description || editMode) &&
          <FormProvider {...form}>
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >

              <TextFieldController
                name={"description"}
                label={"Wprowadź informacje dodatkowe"}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position={"end"}
                      className={classes.inputAdornment}
                    >
                      {value && `${value.length}/500`}
                    </InputAdornment>
                  )
                }}
                maxCharacters={500}
                multiline
              />

              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
              >
                Zapisz
              </Button>
            </form>
          </FormProvider>
        }

      </BoxWithDivider>
    </div>
  )
}

export default AdditionalAnswerResultDescription