import React, {FC} from 'react'
import {ColorLight, QuestionType} from "../examUtils/exam.types";
import {EXAM_QUESTIONS} from "../examUtils/examQuestions.utils";
import {getAnswerColor} from "../examUtils/answerColor.utils";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  createStyles,
} from "@material-ui/core";
import {ExaminedPersonUserItemNode} from "../../../store/user/user.types";
import Paper from "@material-ui/core/Paper";
import {useExamResultsTableStyles} from "./ExamResultsTable.styles";
import clsx from "clsx";
import {Theme} from "@material-ui/core/styles";

interface ExamResultsTableProps {
  userExamData: ExaminedPersonUserItemNode;
}

const ExamResultsTable: FC<ExamResultsTableProps> = (
  {
     userExamData
  }) => {
  const classes = useExamResultsTableStyles()

  const CellWithLeftBorder = withStyles((theme: Theme) =>
    createStyles({
      root: {
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.grey[300],
        borderLeftStyle: "solid",
      },
    })
  )(TableCell);

  const getGreenColor = (isECode: boolean, color: ColorLight[]) => {
    if(isECode && color.every(color => color === ColorLight.GREEN)){
      return (
        <div className={classes.circleWrapper}>
          <div className={clsx(classes.circle, classes.green, "print-circle", classes.marginRight)}/>
          <div className={clsx(classes.circle, classes.green, "print-circle")}/>
        </div>
      )
    } else if(color.includes(ColorLight.GREEN)){
      return (
        <div className={classes.circleWrapper}>
          <div className={clsx(classes.circle, classes.green, "print-circle")}/>
        </div>
      )
    }
  }

  return (
    <Box marginY={5}>
      <Typography
        variant={"h5"}
        component={"span"}
        className={classes.title}
      >
        Zestawienie wyników
      </Typography>

      <div>
        <TableContainer component={Paper} className={"print-tableContainer"}>
          <Table size="small" className={"print-table"}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography className={classes.fieldName}>Kod ICF</Typography>
                </TableCell>

                <TableCell>
                  <Typography className={classes.fieldName}>Nazwa</Typography>
                </TableCell>

                <CellWithLeftBorder align={"center"} width={"130px"} className={"print-cellWithLeftBorder"}>
                  <Typography className={classes.fieldName}>
                    Strefa zielona
                  </Typography>
                </CellWithLeftBorder>

                <CellWithLeftBorder align={"center"} width={"130px"} className={"print-cellWithLeftBorder"}>
                  <Typography className={classes.fieldName}>
                    Strefa pomarańczowa
                  </Typography>
                </CellWithLeftBorder>

                <CellWithLeftBorder align={"center"} width={"130px"} className={"print-cellWithLeftBorder"}>
                  <Typography className={classes.fieldName}>
                    Strefa czerwona
                  </Typography>
                </CellWithLeftBorder>
              </TableRow>
            </TableHead>

            <TableBody>
              { EXAM_QUESTIONS.map((EXAM_QUESTION: QuestionType) => {
                const answersObj = userExamData.answersList.find(answer => answer.category === EXAM_QUESTION.code)
                const isCrucial = EXAM_QUESTION.crucialForOccupations.includes(userExamData.occupationType.occupationTypeId)
                const colorsArr: ColorLight[] = (answersObj && answersObj.answers)
                  ? answersObj.answers
                    .map(( answer )=> getAnswerColor(answer, isCrucial, answersObj.category.charAt(0) === "E"))
                    .filter(item => item) as ColorLight[]
                  : []

                return (
                  <TableRow key={EXAM_QUESTION.code}>
                    <TableCell>
                      {EXAM_QUESTION.code}
                    </TableCell>

                    <TableCell>
                      {EXAM_QUESTION.title}
                    </TableCell>

                    <CellWithLeftBorder align={"center"}>
                      {getGreenColor(EXAM_QUESTION.code.charAt(0) === "E", colorsArr)}
                    </CellWithLeftBorder>

                    <CellWithLeftBorder align={"center"}>
                      { colorsArr.includes(ColorLight.YELLOW) && (
                        <div className={classes.circleWrapper}>
                          <div className={clsx(classes.circle, classes.yellow, "print-circle")}/>
                        </div>
                      )}
                    </CellWithLeftBorder>

                    <CellWithLeftBorder align={"center"}>
                      { colorsArr.includes(ColorLight.RED) && (
                        <div className={classes.circleWrapper}>
                          <div className={clsx(classes.circle, classes.red, "print-circle")}/>
                        </div>
                      )}
                    </CellWithLeftBorder>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  )
}

export default ExamResultsTable