import {QuestionType} from './exam.types'
import {Occupations} from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.types";

export const EXAM_QUESTIONS: QuestionType[] = [
    {
      id: 0,
      code: "B130",
      title: "Funkcje energii życiowej i witalności",
      question: `Ogólne funkcje umysłowe, o podłożu fizjologicznym i psychologicznym, które pobudzają do stałego zaspakajania określonych potrzeb i celów ogólnych. Włącza sie: funkcje poziomu energii, motywacje, apetyt, ogromny głód czegoś, w tymi substancji, które powodują uzależnienie Wyłącza się: funkcje świadomości (b110); temperament i funkcje osobowościowe (b126); funkcje snu (b134); funkcje aktywności psychoruchowej (b147); funkcje emocjonalne (b152)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często w ostatnim czasie nie chciało się Panu/Pani nic robić?",
            scale: 0.2,
            code: "B130_1",
            answers:[
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani ochotę odizolowania się od wszystkich?",
            scale: 0.3,
            code: "B130_2",
            answers:[
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ],
        breakpoint : {
          header: "Jak często musi Pan/Pani  spożywać wymienione substancje?",
          questions: [
            {
              question: "Tytoń",
              scale: 0.1,
              code: "B130_3",
              answers:[
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Alkohol",
              scale: 0.2,
              code: "B130_4",
              answers:[
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Substancje psychoaktywne",
              scale: 0.2,
              code: "B130_5",
              answers:[
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            }
          ]
        }
      }
    },
    {
      id: 1,
      code: "B134",
      title: "Funkcje snu",
      question: `Ogólne funkcje umysłowe okresowego, odwracalnego i wybiórczego, fizycznego i umysłowego wyłączania się ze swojego najbliższego otoczenia, któremu towarzyszą charakterystyczne zmiany fizjologiczne. Obejmuje: funkcje ilości snu, początku snu, utrzymywanie snu, jakość snu, cykl snu, bezsenność, nadmierną potrzebę snu, sen napadowy Nie obejmuje: funkcje świadomości (b110); funkcje energii życiowej i napędu (b130); funkcje uwagi (b140); funkcje aktywności psychoruchowej (b147)`,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często w ostatnim czasie odczuwa Pan/Pani problemy ze snem?",
            scale: 0.2,
            code: "B134_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie wybudzał/a się Pan/Pani w nocy z trudnością szybkiego zaśnięcia?",
            scale: 0.2,
            code: "B134_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często spał/a Pan/Pani powyżej 12 godzin dziennie?",
            scale: 0.2,
            code: "B134_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często nie mógł/mogła Pan/Pani zasnąć bez środków nasennych lub uspokajających?",
            scale: 0.4,
            code: "B134_4",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 2,
      code: "B140",
      title: "Funkcje uwagi",
      question: `Swoiste funkcje psychiczne umożliwiające skupienie się przez niezbędny odstęp czasu na bodźcu zewnętrznym lub przeżyciach wewnętrznych. Obejmuje: funkcje trwałości, przerzutności, zmiennej podzielności i dzielenia uwagi, koncentracja, rozpraszalność Nie obejmuje: funkcje świadomości (b110); funkcje energii i napędu (b130); funkcje snu (b134); funkcje pamięci (b144); funkcje psychomotoryczne (b147); funkcje percepcyjne (b156)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często w ostatnim czasie miał/a Pan/i problemy z koncentracją  uwagi przez dłuższy czas?",
            scale: 0.25,
            code: "B140_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/i problemy z  podzielnością uwagi?",
            scale: 0.25,
            code: "B140_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/i problemy z  utrzymaniem  uwagi w hałasie i gwarze?",
            scale: 0.25,
            code: "B140_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/i problemy z  przerzucaniem uwagi z jednej czynności na drugą?",
            scale: 0.25,
            code: "B140_4",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 3,
      code: "B144",
      title: "Funkcje pamięci",
      question: `Swoiste funkcje psychiczne umożliwiające rejestrowanie i przechowywanie informacji oraz - w razie potrzeby – odtwarzanie jej. Obejmuje: funkcje związane z pamięcią krótkotrwałą i długotrwałą, natychmiastową, świeżą i odległą; rozpiętość pamięci; odtwarzanie pamięci, przypominanie; funkcje wykorzystywane w trakcie przywoływania z pamięci i uczenia się jak w niepamięci nominalnej, wybiórczej i dysocjacyjnej. Nie obejmuje: funkcje świadomości (b110); funkcje orientacji (b114); funkcje intelektualne (b117); funkcje uwagi (b140); funkcje percepcyjne (b156); funkcje myślenia (b160); wyższe funkcje poznawcze (b164); funkcje językowe (b167); funkcje liczenia (b172)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Czy często ma Pan/i wrażenie, że nie pamięta, co miał/a zrobić?",
            scale: 0.25,
            code: "B144_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy często zdarzają się Panu/i sytuacje z zapominaniem imion?",
            scale: 0.25,
            code: "B144_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy będąc w sklepie, zdarza się Panu/i zapomnieć co kupić?",
            scale: 0.25,
            code: "B144_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy zdarza się Panu/i zapomnieć ważne daty ze swojego życia?",
            scale: 0.25,
            code: "B144_4",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 5,
      code: "B152",
      title: "Funkcje emocjonalne",
      question: `Swoiste funkcje psychiczne odnoszące się do uczuć i afektywnych składników procesów umysłu. Obejmuje: funkcje związane z dostosowaniem emocji, regulacją i rozpiętością emocji; afekt, smutek, szczęście, miłość, strach, gniew, nienawiść, napięcie, lęk, radość, żal, chwiejność emocjonalną; spłycenie afektu Nie obejmuje: funkcje temperamentu i osobowości (b126); funkcje energii i napędu (b130)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często w ostatnim czasie odczuwał(-a) Pan/Pani skrajny smutek lub gniew?",
            scale: 0.25,
            code: "B152_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie odczuwał(-a) Pan/Pani nienawiść do konkretnej osoby?",
            scale: 0.25,
            code: "B152_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie odczuwał(-a) Pan/Pani napięcie emocjonalne lub lęk?",
            scale: 0.25,
            code: "B152_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie doświadczał(-a) Pan/Pani chwiejności emocjonalnej?",
            scale: 0.25,
            code: "B152_4",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 6,
      code: "B240",
      title: "Wrażenia związane z funkcją słyszenia i funkcją przedsionka",
      question: `Uczucie zaburzonej równowagi, upadania, szumów usznych, zawrotów głowy. Obejmuje: uczucie dzwonienia w uszach, podrażnienia uszu, ucisk uszny, nudności związane z kręceniem się w głowie lub zawrotami głowy Nie obejmuje: funkcje przedsionka (b235); czucie bólu (b280)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często zdarzają się Panu/Pani zawroty głowy z towarzyszącymi mdłościami?",
            scale: 0.3,
            code: "B240_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często zdarza się Panu/Pani uczucie braku równowagi, chwiania się?",
            scale: 0.4,
            code: "B240_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często doświadcza Pan/Pani dzwonienia/brzęczenia/szumów w uszach?",
            scale: 0.3,
            code: "B240_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 7,
      code: "B280",
      title: "Czucie bólu",
      question: `Wrażenie nieprzyjemnego doznania sygnalizującego potencjalne lub faktyczne uszkodzenie struktury ciała. Obejmuje: uczucie uogólnionego lub umiejscowionego bólu w jednej lub więcej niż w jednej części ciała, ból w dermatomie, ból kłujący, ból piekący, ból tępy, pobolewania, upośledzenia jak: ból mięśniowy, analgezja, przeczulica bólowa`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często przyjmuje Pan/Pani leki przeciwbólowe?",
            code: "B280_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ],
        breakpoint: {
          header: "Czy odczuwa Pani/Pan ból przeszkadzający w codziennym funkcjonowaniu (podczas wykonywania poniższych czynności):",
          questions: [
            {
              question: "Chodzenie",
              code: "B280_2",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Długotrwałe siedzenie",
              code: "B280_3",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Wykonywania codziennych obowiązków w pracy",
              code: "B280_4",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
          ]
        }
      }
    },
    {
      id: 8,
      code: "B420",
      title: "Funkcje związane z ciśnieniem tętniczym krwi",
      question: `Funkcje utrzymywania odpowiedniego ciśnienia tętniczego krwi. Obejmuje: funkcje utrzymywania ciśnienia tętniczego krwi; podwyższone i obniżone ciśnienie krwi; upośledzenia związane z niedociśnieniem, nadciśnieniem i hipotonią ortostatyczną Nie obejmuje: funkcje serca (b410); funkcje naczyń krwionośnych (b415); funkcje związane z tolerancją wysiłku (b455)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 9,
      code: "B455",
      title: "Funkcje związane z tolerancją wysiłku",
      question: `Funkcje wydolności oddechowej i krążeniowej wymaganej do ciągłego wysiłku fizycznego. Obejmuje: funkcje wydolności fizycznej, pochłaniania tlenu; wytrzymałości i zmęczenia Nie obejmuje: funkcje układu krążenia (b410-b429); funkcje układu krwiotwórczego (b430); funkcje oddychania (b440); funkcje mięśni oddechowych (b445); dodatkowe funkcje związane z oddychaniem (b450)`,
      crucialForOccupations: [Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często w ostatnim czasie (pół roku) uprawia Pan/Pani różne formy aktywności fizycznej przynajmniej 30 min jednorazowo",
            code: "B455_1",
            answers: [
              {value: 0, description: "3 razy i więcej", label: "0"},
              {value: 1, description: "Raz w tygodniu", label: "1"},
              {value: 2, description: "Sporadycznie 1-2 w miesiącu", label: "2"},
              {value: 4, description: "Wcale", label: "4"},
            ]
          },
        ],
        breakpoint: {
          header: "Czy w ostatnim czasie (pół roku) czuł Pan/Pani zmęczenie/przyśpieszony oddech/podwyższone tętno (tzw. Zadyszka) podczas wykonywania następujących czynności:",
          questions: [
            {
              question: "Sprzątanie przez co najmniej 30 minut",
              code: "B455_2",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Wchodzenie po schodach na 1 piętro",
              code: "B455_3",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Wchodzenie po schodach na 3 piętro",
              code: "B455_4",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Podbiegnięcie do autobusu/tramwaju",
              code: "B455_5",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Przenoszenie zakupów (5-10 kg)",
              code: "B455_6",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
          ]
        }
      }
    },
    {
      id: 10,
      code: "B710",
      title: "Funkcje ruchomości stawów",
      question: `Funkcje związane z zakresem i łatwością wykonywania ruchów w stawach. Obejmuje: funkcje związane z ruchomością pojedynczego lub kilku stawów, kręgosłupa, barku, łokcia, nadgarstka, biodra, kolana, stawu skokowego, drobnych stawów rąk i stóp; ogólna ruchomość stawów, upośledzenia funkcji stawów takie jak: nadmierna ruchomość stawów (hipermobilność), ograniczenie lub brak ruchu stawu, bark unieruchomiony „bark zamrożony”, choroba zwyrodnieniowa Nie obejmuje: funkcje stabilności stawów (b715); funkcje związane z kontrolowaniem ruchów dowolnych (b760) `,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często w ostatnim czasie miał/a Pan/Pani problem z przyjęciem pozycji kucznej (kucanie)?",
            code: "B710_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/Pani problem z przyjęciem pozycji klęcznej?",
            code: "B710_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Do jakiego poziomu jest Pan/Pani w stanie wykonać skon przy wyprostowanych kolanach?",
            code: "B710_3",
            answers: [
              {value: 0, description: "Palce rąk sięgają podłogi lub do kostek", label: "0"},
              {value: 1, description: "Do połowy łydki", label: "1"},
              {value: 2, description: "Do kolan", label: "2"},
              {value: 4, description: "Nie mogę wykonać skłonu", label: "4"},
            ]
          },
          {
            question: "Czy jest Pan/Pani w stanie przyjąć pozycje z wyprostowanymi kończynami górnymi do przodu na wysokości barków?",
            code: "B710_4",
            answers: [
              {value: 0, description: "Tak", label: "0"},
              {value: 2, description: "Tak, z trudnością", label: "2"},
              {value: 4, description: "Nie", label: "4"},
            ]
          },
          {
            question: "Czy jest Pan/Pani w stanie przyjąć pozycje z wyprostowanymi kończynami górnymi odwiedzione do boku na wysokość barków?",
            code: "B710_5",
            answers: [
              {value: 0, description: "Tak", label: "0"},
              {value: 2, description: "Tak, z trudnością", label: "2"},
              {value: 4, description: "Nie", label: "4"},
            ]
          },
          {
            question: "Czy jest Pan/Pani w stanie przyjąć pozycje z wyprostowanymi kończynami górnymi uniesionymi nad głowę?",
            code: "B710_6",
            answers: [
              {value: 0, description: "Tak", label: "0"},
              {value: 2, description: "Tak, z trudnością", label: "2"},
              {value: 4, description: "Nie", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 11,
      code: "B730",
      title: "Funkcje związane z siłą mięśni",
      question: `Funkcje związane z siłą wytwarzaną przez skurcz pojedynczego mięśnia lub grup mięśni. Obejmuje: funkcje związane z siłą określonych mięśni i grup mięśni, mięśni jednej kończyny, mięśni jednej strony ciała, mięśni dolnej połowy ciała, mięśni wszystkich kończyn, mięśni tułowia i wszystkich mięśni ciała; upośledzenie funkcji jak osłabienie małych mięśni stóp i rąk niedowład mięśnia, porażenie mięśnia, porażenie jednej kończyny, porażenie połowicze, porażenie kończyn dolnych, porażenie czterokończynowe, mutyzm akinetyczny (brak zdolności wykonywania ruchów - bezruch) Nie obejmuje: funkcje narządów dodatkowych oka (b215); funkcje związane z obecnością napięcia mięśni (b735); funkcje związane z wytrzymałością mięśni (b740)`,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Czy jest Pani/Pan w stanie przenieść zgrzewkę wody (ok. 9kg) z poziomu podłogi do koszyka sklepowego?",
            code: "B730_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy jest Pani/Pan w stanie przenieść zgrzewkę wody (ok. 9kg) z koszyka sklepowego na taśmę przy kasie w sklepie?",
            code: "B730_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ],
        breakpoint: {
          header: "Czy w ostatnim czasie odczuwa Pani/Pan osłabienie siły:",
          questions: [
            {
              question: "Chwytu lewej ręki (dłoni)",
              code: "B730_3",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Chwytu prawej ręki (dłoni)",
              code: "B730_4",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Lewej kończyny górnej",
              code: "B730_5",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Prawej kończyny górnej",
              code: "B730_6",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Lewej kończyny dolnej",
              code: "B730_7",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Prawej kończyny dolnej",
              code: "B730_8",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Pleców/brzucha",
              code: "B730_9",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
          ]
        }
      }
    },
    {
    id: 12,
    code: "D110",
    title: "Patrzenie",
    question: `Zamierzone posługiwanie się zmysłem wzroku w celu odbioru bodźców wzrokowych, jak np. podczas oglądania wydarzenia sportowego lub obserwowania bawiących się dzieci.`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
      {value: 0, description: "Brak problemu", label: "0"},
      {value: 1, description: "Łagodny problem", label: "1"},
      {value: 2, description: "Umiarkowany problem", label: "2"},
      {value: 3, description: "Znaczny problem", label: "3"},
      {value: 4, description: "Skrajny problem", label: "4"},
      {value: 8, description: "Nieokreślone", label: "8"},
      {value: 9, description: "Nie dotyczy", label: "9"}
    ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często ma Pan/Pani  problem z widzeniem przedmiotów podczas patrzenia w dal (np. odczytaniem nr nadjeżdżającego autobusu, oglądaniem telewizji)?",
            code: "D110_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często ma Pan/Pani problemy z widzeniem z bliska (w czasie korzystania z telefonu/czytania książki)?",
            code: "D110_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy ma Pan/Pani problem z rozróżnianiem barw?",
            code: "D110_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy po ciemku zauważa Pan/Pani u siebie gorsze widzenie?",
            code: "D110_4",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
  },
    {
      id: 13,
      code: "D115",
      title: "Słuchanie",
      question: `Zamierzone posługiwanie się zmysłem słuchu w celu odbioru bodźców słuchowych, jak np. podczas słuchania radia, muzyki lub wykładu.`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często prosi Pan/Pani innych, aby powtórzyli swoją wypowiedź?",
            code: "D115_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często ma Pan/Pani problem ze zrozumieniem mowy w głośnym otoczeniu (kiedy panuje hałas)?",
            code: "D115_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często ogląda Pan/Pani telewizję lub słucha radia zdecydowanie głośniej niż inni?",
            code: "D115_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 14,
      code: "D160",
      title: "Skupianie uwagi",
      question: `Celowe skupianie się na określonym bodźcu, np. przez eliminowanie rozpraszających dźwięków.`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani problemy ze skupieniem uwagi kiedy panuje gwar i hałas?",
            scale: 0.3,
            code: "D160_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani problemy ze skupieniem uwagi na jednym rodzaju pracy przez dłuższy czas?",
            scale: 0.3,
            code: "D160_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani wrażenie, że wszystko wokół Pana/Panią rozprasza?",
            scale: 0.4,
            code: "D160_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 15,
      code: "D175",
      title: "Rozwiązywanie problemów",
      question: `Znajdowanie odpowiedzi na pytania lub sytuacje poprzez identyfikowanie i analizowanie problemów, ustalanie możliwych rozwiązań i ocenianie potencjalnych skutków tych rozwiązań a następnie wdrażanie wybranego rozwiązania, jak np. rozstrzyganie sporu pomiędzy dwoma osobami. Obejmuje: rozwiązywanie prostych i złożonych problemów Nie obejmuje: myślenie (d163), podejmowanie decyzji (d177)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani trudności z rozwiązywaniem problemów zawodowych?",
            scale: 0.3,
            code: "D175_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani trudności z rozwiązywaniem problemów prywatnych lub rodzinnych?",
            scale: 0.3,
            code: "D175_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani trudności z ocenianiem skutków sytuacji problemowych?",
            scale: 0.4,
            code: "D175_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 17,
      code: "D177",
      title: "Podejmowanie decyzji",
      question: `Dokonywanie wyboru spośród różnych możliwości, wdrażanie dokonanego wyboru, ocenianie skutków dokonanego wyboru, jak np. wybieranie i kupowanie określonego artykułu lub decydowanie o podjęciu się i podejmowanie jakiegoś zadania spośród wielu zadań, które należy wykonać. Nie obejmuje: myślenie (d163), rozwiązywanie problemów (d175)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani problemy z podejmowaniem decyzji w życiu zawodowym?",
            scale: 0.3,
            code: "D177_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani problemy z podejmowaniem decyzji w  prostych sytuacjach wyboru (np. produktu w sklepie)?",
            scale: 0.3,
            code: "D177_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często był/a  Pan/Pani zaskoczona własnymi wyborami po pewnym czasie?",
            scale: 0.3,
            code: "D177_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 18,
      code: "D240",
      title: "Radzenie sobie ze stresem i innymi obciążeniami psychicznymi",
      question: `Wykonywanie prostych lub złożonych i skoordynowanych działań, związanych z kontrolowaniem obciążeń psychicznych występujących podczas wykonywania zadań, które wiążą się z ponoszeniem znacznej odpowiedzialności, narażeniem na stres, czynniki zakłócające lub sytuacje kryzysowe, jak np. w trakcie kierowania pojazdem w czasie wielkiego natężenia ruchu ulicznego lub sprawowania opieki nad liczną grupą dzieci. Obejmuje: ponoszenie odpowiedzialności; radzenie sobie ze stresem i sytuacjami kryzysowymi.`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani problemy z radzeniem sobie z napięciem w trudnych sytuacjach w pracy?",
            scale: 0.3,
            code: "D240_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani problemy z opanowaniem się w sytuacjach kryzysowych?",
            scale: 0.3,
            code: "D240_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani niekontrolowane reakcje w sytuacjach trudnych emocjonalnie?",
            scale: 0.4,
            code: "D240_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 19,
      code: "D360",
      title: "Używanie urządzeń i technik służących do porozumiewania się",
      question: `Wykorzystywanie urządzeń, technik i innych środków do porozumiewania się,  np. używanie telefonu aby zadzwonić do przyjaciela. Obejmuje: używanie urządzeń telekomunikacyjnych, używanie maszyn do pisania i technik do porozumiewania się`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani trudności z zatelefonowaniem do znajomej osoby?",
            scale: 0.3,
            code: "D360_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani trudności z wysłaniem sms-a do znajomej osoby?",
            scale: 0.3,
            code: "D360_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani trudności z wysłaniem maila do znajomej osoby?",
            scale: 0.4,
            code: "D360_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 20,
      code: "D415",
      title: "Utrzymywanie pozycji ciała",
      question: `Pozostawanie w tej samej wymaganej pozycji ciała, tak jak np. siedzenie lub stanie w pracy lub w szkole. Obejmuje: utrzymywanie pozycji leżącej, kucznej, klęczącej, siedzącej i stojącej`,
      crucialForOccupations: [Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często w ostatnim czasie miał/a Pan/Pani problem z utrzymaniem pozycji kucznej (kucanie)?",
            code: "D415_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/Pani problem z utrzymaniem pozycji klęcznej?",
            code: "D415_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/Pani problem z utrzymaniem pozycji stojącej, wyprostowanej przez co najmniej 30 minut?",
            code: "D415_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/Pani problem z utrzymaniem pozycji z wyprostowanymi kończynami górnymi do przodu na wysokości barków?",
            code: "D415_4",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/Pani problem z utrzymaniem pozycji z wyprostowanymi kończynami górnymi odwiedzione do boku na wysokość barków?",
            code: "D415_5",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często w ostatnim czasie miał/a Pan/Pani problem z utrzymaniem pozycji z wyprostowanymi kończynami górnymi uniesionymi nad głowę?",
            code: "D415_6",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 21,
      code: "D430",
      title: "Podnoszenie i przenoszenie przedmiotów",
      question: `Podnoszenie przedmiotu lub zabieranie czegoś z jednego miejsca na drugie, tak jak np. podczas unoszenia filiżanki lub przenoszenia dziecka z jednego pokoju do drugiego. Obejmuje: podnoszenie, przenoszenie za pomocą rąk, na barkach, na biodrach, na plecach lub na głowie, odkładanie`,
      crucialForOccupations: [Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Czy jest Pan/Pani przenieść przedmioty, np. szklanka, blacha z ciastem, segregator pełen dokumentów (przedmioty do 3 kg) z miejsca na miejsce lub z jednego poziomu na drugi?",
            code: "D430_1",
            answers: [
              {value: 0, description: "Tak", label: "0"},
              {value: 2, description: "Tak, z trudnością", label: "2"},
              {value: 4, description: "Nie", label: "4"},
            ]
          },
          {
            question: "Czy jest Pani/Pan w stanie przenieść zakupy/zgrzewkę wody (ok. 9kg) z poziomu podłogi do koszyka sklepowego?",
            code: "D430_2",
            answers: [
              {value: 0, description: "Tak", label: "0"},
              {value: 2, description: "Tak, z trudnością", label: "2"},
              {value: 4, description: "Nie", label: "4"},
            ]
          },
          {
            question: "Czy jest Pani/Pan w stanie przenieść zakupy/zgrzewkę wody (ok. 9kg) z koszyka sklepowego na taśmę przy kasie w sklepie?",
            code: "D430_3",
            answers: [
              {value: 0, description: "Tak", label: "0"},
              {value: 2, description: "Tak, z trudnością", label: "2"},
              {value: 4, description: "Nie", label: "4"},
            ]
          },
          {
            question: "Czy jest Pani/Pan w stanie przenieść zakupy/zgrzewkę wody (ok. 9kg) ze sklepu do samochodu (odległość około 30 m)?",
            code: "D430_4",
            answers: [
              {value: 0, description: "Tak", label: "0"},
              {value: 2, description: "Tak, z trudnością", label: "2"},
              {value: 4, description: "Nie", label: "4"},
            ]
          },
          {
            question: "Czy jest Pani/Pan w stanie przenieść zakupy/zgrzewkę wody (ok. 9kg) ze sklepu do domu?",
            code: "D430_5",
            answers: [
              {value: 0, description: "Tak", label: "0"},
              {value: 2, description: "Tak, z trudnością", label: "2"},
              {value: 4, description: "Nie", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 22,
      code: "D440",
      title: "Precyzyjne używanie ręki",
      question: `Wykonywanie skoordynowanych czynności związanych z posługiwaniem się przedmiotami, podnoszeniem, manipulowaniem i wypuszczaniem przedmiotów przy użyciu jednej ręki, palców i kciuka, jak np. gdy zbiera się monety ze stołu lub wykręca numer telefonu. Obejmuje: podnoszenie, chwytanie, manipulowanie i odkładanie Nie obejmuje: podnoszenie i przenoszenie przedmiotów (d430)`,
      crucialForOccupations: [ Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        breakpoint: {
          header: "Czy w ostatnim czasie miał/a Pani/Pan problemy z:",
          questions: [
            {
              question: "Chwytaniem i unoszeniem małych przedmiotów, jak np. długopisu, zapinanie guzików w koszuli",
              code: "D440_1",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Trzymaniem młotka, wbijaniem gwoździ lub chwytanie klamki i otwieranie drzwi",
              code: "D440_2",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Chwytaniem noża i krojeniem",
              code: "D440_3",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Korzystaniem z klawiatury telefonu",
              code: "D440_4",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Włożeniem klucza do zamka",
              code: "D440_5",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
          ]
        }
      }
    },
    {
      id: 23,
      code: "D445",
      title: "Używanie rąk i ramion",
      question: `Podejmowanie skoordynowanych działań, których celem jest poruszanie lub manipulowanie przedmiotami za pomocą rąk i ramion, tak jak np. gdy obraca się gałkę u drzwi lub gdy rzuca się lub łapie jakiś przedmiot. Obejmuje: przyciąganie lub popychanie przedmiotów, obracanie lub skręcanie rąk lub ramion; rzucanie, łapanie; Nie obejmuje: precyzyjne używanie ręki (d440)`,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        breakpoint: {
          header: "Czy w ostatnim czasie miał/a Pani/Pan problemy z:",
          questions: [
            {
              question: "Unoszeniem rąk podczas mycia głowy",
              code: "D445_1",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Sięganiem po przedmioty znajdujące się powyżej głowy",
              code: "D445_2",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Przyjmowaniem różnych pozycji rąk: chwytaniem noża i krojeniem",
              code: "D445_3",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Przemieszczaniem przedmiotu ku sobie lub przemieszczania go z miejsca na miejsce np. przyciąganie, otwieranie , zamykanie drzwi",
              code: "D445_4",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Sięgnięciem i przemieszczeniem w swoją stronę przedmiotu, np. pełnego półmiska znajdującego się po drugiej stronie stołu",
              code: "D445_5",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Przyjęciem pozycji z wyprostowanymi kończynami górnymi do przodu na wysokości barków",
              code: "D445_6",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Przyjęciem pozycji z wyprostowanymi kończynami górnymi odwiedzione do boku na wysokość barków?",
              code: "D445_7",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Przyjęciem pozycji z wyprostowanymi kończynami górnymi uniesionymi nad głowę?",
              code: "D445_8",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
          ]
        }
      }
    },
    {
      id: 24,
      code: "D450",
      title: "Chodzenie",
      question: `Poruszanie się po powierzchni na stopach, krok po kroku, gdy co najmniej jedna stopa zawsze dotyka ziemi, tak jak np. podczas spacerowania, przechadzania się, chodzenia do przodu, do tyłu lub bokiem. Obejmuje: chodzenie na krótkie lub długie dystanse, chodzenie po różnych powierzchniach, omijanie przeszkód Nie obejmuje:  przemieszczanie się (d420); inne sposoby poruszania się (d455)`,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        breakpoint: {
          header: "Czy w ostatnim czasie miał/a Pani/Pan problemy z:",
          questions: [
            {
              question: "Chodzeniem po płaskim równym podłożu",
              code: "D450_1",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Chodzeniem po nierównym podłożu",
              code: "D450_2",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Przechodzeniem przez przeszkody np. progi",
              code: "D450_3",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Chodzeniem po schodach",
              code: "D450_4",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "Chodzeniem na dłuższe dystanse np. 5 km",
              code: "D450_5",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
          ]
        }
      }
    },
    {
      id: 25,
      code: "D475",
      title: "Prowadzenie pojazdu",
      question: `Kontrolowanie i kierowanie pojazdem lub zwierzęciem które go porusza, podróżowanie w wybranym przez siebie kierunku lub dysponowanie środkiem transportu, takim jak np. samochód, rower, łódź lub pojazd poruszany przez zwierzęta. Obejmuje: kierowanie pojazdem poruszanym siłą mięśni ludzkich; pojazdem zmotoryzowanym, pojazdem poruszanym przez zwierzęta Nie obejmuje: poruszanie się przy pomocy sprzętu (d465), używanie środków transportu (d470)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
    },
    {
      id: 26,
      code: "D720",
      title: "Złożone kontakty międzyludzkie",
      question: `Utrzymywanie i kontrolowanie wzajemnych kontaktów z innymi ludźmi, w sposób odpowiedni do danej sytuacji i akceptowany społecznie tak jak np. kontrolowanie emocji i odruchów, kontrolowanie werbalnej i fizycznej agresji, działanie niezależne od relacji społecznych, działanie zgodnie z zasadami i zwyczajami społecznymi. Obejmuje: tworzenie i kończenie wzajemnych kontaktów; kontrolowanie zachowań w ramach wzajemnych kontaktów, nawiązywanie wzajemnych kontaktów stosownie do pełnionych ról społecznych i zachowywanie dystansu w relacjach społecznych`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani problemy ze współpracą z kolegami w pracy z powodu niekontrolowanych zachowań?",
            code: "D720_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani problemy z innymi osobami w sytuacjach społecznych (np. w sklepie)?",
            code: "D720_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani niekontrolowane reakcje agresji w sytuacjach społecznych?",
            code: "D720_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
    id: 27,
    code: "E1101",
    title: "Leki",
    question: `Każdy naturalny lub wytworzony przez człowieka produkt lub substancja, gromadzony, przetwarzany lub produkowany w celach leczniczych taki jak leki alopatyczne i naturopatyczne.`,
    crucialForOccupations:[],
      answers: [
      {value: 14, description: "Całkowite ułatwienie", label: "+4"},
      {value: 13, description: "Znaczne ułatwienie", label: "+3"},
      {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
      {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
      {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
      {value: 1, description: "Nieznaczna bariera", label: "1"},
      {value: 2, description: "Umiarkowana bariera", label: "2"},
      {value: 3, description: "Znaczna bariera", label: "3"},
      {value: 4, description: "Całkowita bariera", label: "4"},
      {value: 8, description: "Nieokreślone", label: "8"},
      {value: 9, description: "Nie dotyczy", label: "9"}
    ]
  },
    {
      id: 28,
      code: "E115",
      title: "Produkty i technologie do osobistego użytku w życiu codziennym",
      question: `Sprzęt, wyroby i technologie stosowane przez ludzi w codziennej działalności, w tym przystosowane lub specjalnie zaprojektowane, umiejscowione wewnątrz, na ciele lub w pobliżu używającej je osoby. Obejmuje: ogólne i wspomagające produkty i technologia do użytku osobistego`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 29,
      code: "E125",
      title: "Produkty i technologie służące do porozumiewania się",
      question: `Wyposażenie, produkty i technologie wykorzystywane przez ludzi do wysyłania i odbierania informacji, z włączeniem tych, które zostały specjalnie zaadaptowane lub zaprojektowane, umiejscowione wewnątrz, na ciele lub w pobliżu używającej je osoby Obejmuje: ogólne i wspomagające produkty i technologie używane do porozumiewania się.`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 30,
      code: "E135",
      title: "Produkty i technologie stosowane w miejscu zatrudnienia",
      question: `Wyposażenie, produkty i technologie stosowane w miejscu zatrudnienia dla ułatwienia czynności związanych z pracą. Obejmuje: ogólne i wspomagające produkty i technologie stosowane w pracy`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 31,
      code: "E310",
      title: "Najbliższa rodzina",
      question: `Osoby spokrewnione przez urodzenie, małżeństwo lub inne związki uznawane zgodnie z normami kulturowymi za najbliższą rodzinę jak: małżonkowie, partnerzy, rodzice, rodzeństwo, dzieci, rodzina zastępcza, rodzice adopcyjni i dziadkowie. Nie obejmuje: dalszą rodzinę (e315); opiekunów asystentów osobistych (e340)`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 32,
      code: "E320",
      title: "Przyjaciele",
      question: `Osoby, które pozostają w bliskich i aktualnie istniejących relacjach charakteryzujących się zaufaniem i wzajemnym wsparciem`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 33,
      code: "E325",
      title: "Znajomi, rówieśnicy, koledzy, sąsiedzi i członkowie lokalnej społeczności",
      question: `Osoby wzajemnie znające się jako rówieśnicy, koledzy, sąsiedzi i członkowie lokalnej społeczności, w sytuacjach takich jak praca, szkoła, rekreacja lub inne aspekty życia, oraz posiadające wspólne cechy demograficzne takie jak wiek, płeć, wyznanie religijne lub pochodzenie etniczne lub realizujące wspólne zainteresowania. Nie obejmuje: stowarzyszenia i usługi związane ze zrzeszaniem się (e5550)`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 34,
      code: "E330",
      title: "Ludzie posiadający władzę (zwierzchnicy)",
      question: `Osoby, które ponoszą odpowiedzialność za podejmowanie decyzji na rzecz innych i mają społecznie uznany wpływ lub władzę wynikającą z ich społecznej, ekonomicznej, kulturowej lub religijnej roli w społeczeństwie, jak np.: nauczyciele, pracodawcy, osoby sprawujące nadzór, przywódcy religijni, decydenci, kuratorzy, powiernicy.`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 35,
      code: "E335",
      title: "Podwładni",
      question: `Osoby, na których codzienne życie wywierają wpływ ludzie posiadający władzę (zwierzchnicy) w pracy, w szkole lub innych okolicznościach, jak: studenci, pracownicy, członkowie grup religijnych. Nie obejmuje: najbliższą rodzinę (e310)`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 36,
      code: "E430",
      title: "Postawy osób posiadających władzę (zwierzchników) ",
      question: `Ogólne lub określone opinie i przekonania osób posiadających autorytet o jakiejś osobie lub o innych kwestiach (np. społecznych, politycznych i ekonomicznych), które wpływają na indywidualne zachowanie i działanie.`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 37,
      code: "E435",
      title: "Postawy podwładnych",
      question: `Ogólne lub określone opinie i przekonania podwładnych o danej osobie lub o innych kwestiach (np. społecznych, politycznych i ekonomicznych), które wpływają na indywidualne zachowanie i działanie.`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
    {
      id: 38,
      code: "E590",
      title: "Usługi , systemy i polityka w zakresie pracy i zatrudnienia ",
      question: `Usługi, systemy i polityka dotyczące wyszukiwania odpowiedniej pracy dla osób bezrobotnych lub poszukujących innej pracy lub wspieraniu osób już zatrudnionych, które poszukują możliwości rozwoju. Nie obejmuje: usługi, systemy i polityka związane z gospodarką (e565)`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ]
    },
  ]