import {FC} from 'react'
import {Card, Typography} from "@material-ui/core";
import {ExamResultTypes} from "../testedPersons/examsResults/ExamsResult.types";
import {advisoryActivities} from "../examComponents/examUtils/advisoryActivitiesCatalog.utils";
import BookItem from "../examComponents/bookItem/BookItem.component";
import {useAdvisoryActivitiesCardStyles} from "./AdvisoryActivitiesCard.styles";
import clsx from "clsx";

interface AdvisoryActivitiesCardProps {
  resultType: ExamResultTypes
}

const AdvisoryActivitiesCard: FC<AdvisoryActivitiesCardProps> = ({resultType}) => {
  const styles = useAdvisoryActivitiesCardStyles()

  return (
    <Card className={clsx(styles.root, "print-exam-advisory-card")}>
      <Typography
        variant="h5"
        component="p"
        className={styles.title}
      >
        Dodatkowe żródło informacji
      </Typography>

      {advisoryActivities.map(code => (
        code.books.map(book => book?.resultType?.includes(resultType) && (
          <BookItem
            key={book.title}
            title={book.title}
            author={book.author}
            link={book.link}
          />
        ))
      ))}
    </Card>
  )
}

export default AdvisoryActivitiesCard