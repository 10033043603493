import {ExamResultTypes} from "./ExamsResult.types";

export const getExamResultsTypeForPdf = (type: ExamResultTypes) => {
  switch (type){
    case ExamResultTypes.GUARDIAN_RESULT: return "guardian"
    case ExamResultTypes.EXAMINED_PERSON_RESULTS: return "examined-person"
    default: return type
 }
}

export const getExamResultsTypeLabel = (type: ExamResultTypes) => {
  switch (type){
    case ExamResultTypes.GUARDIAN_RESULT: return "Wyniki badania dla pracodawcy"
    case ExamResultTypes.EXAMINED_PERSON_RESULTS: return "Wyniki badania dla pracownika"
    default: return "Wyniki badania"
  }
}