import {makeStyles, createStyles, Theme} from '@material-ui/core/styles'

export const useExamAnswerStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    margin: theme.spacing(2,0),
    padding: theme.spacing(2),
  },
  wrapper: {
    display: "flex",
    margin: theme.spacing(1,0),
    flexDirection: "column",
  },
  sectionTitle: {
    margin: theme.spacing(1, 0)
  },
  circle: {
    width: "20px",
    height: "20px",
    borderRadius: "100%",
    marginRight: theme.spacing(2),
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  colorWrapper: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1,0)
  },
  red: {
    backgroundColor: "#c0392b",
    boxShadow: "0 0 10px 2px #c0392b",
  },
  yellow: {
    backgroundColor: "#f1c40f",
    boxShadow: "0 0 10px 2px #f1c40f",
  },
  green: {
    backgroundColor: "#2ecc71",
    boxShadow: "0 0 10px 2px #2ecc71",
  },
  answerHeader: {
    marginBottom: theme.spacing(1)
  },
  listItem: {
   margin: theme.spacing(1,0)
  }
}))