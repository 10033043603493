import {ColorLight} from "../examUtils/exam.types";
import {Color} from "@material-ui/lab/Alert/Alert";

export const getAlertSeverity = (color: ColorLight): Color => {
  switch (color) {
    case ColorLight.GREEN: return "success"
    case ColorLight.YELLOW: return "warning"
    case ColorLight.RED: return "error"
  }

  throw new Error(`Unknown answer color: ${color}`);
}

export const getGeneralDescription = (color: ColorLight): string => {
  switch (color) {
    case ColorLight.GREEN:
      return "W oparciu o przeprowadzone badania kwestionariuszowe, ocenę zdolności do pracy oraz informacje o stanowisku pracy można stwierdzić, że organizacja pracy jest dobrze dostosowana do możliwości psychofizycznych badanego pracownika. Pozwala to na wykonywaniem powierzonych mu obowiązków bez nadmiernego obciążenia pracą. Jednakże można zwrócić uwagę na kilka aspektów sprzyjających zapewnieniu zdrowia i dobrej kondycji oraz jednocześnie dobrej efektywności pracy przez cały okres aktywności zawodowej."
    case ColorLight.YELLOW:
      return "W oparciu o przeprowadzone badania kwestionariuszowe, ocenę zdolności do pracy oraz informacje o stanowisku pracy można stwierdzić, że organizacja pracy jest odpowiednio dostosowana do możliwości psychofizycznych badanego pracownika. Pozwala to na wykonywaniem powierzonych mu obowiązków bez nadmiernego obciążenia pracą, wskazane jest jednak wdrożenie niewielkich modyfikacji w zakresie organizacji pracy. Należy zwrócić uwagę na kilka aspektów sprzyjających zapewnieniu zdrowia i dobrej kondycji oraz jednocześnie dobrej efektywności pracy przez cały okres aktywności zawodowej."
    case ColorLight.RED:
      return "W oparciu o przeprowadzone badania kwestionariuszowe, ocenę zdolności do pracy oraz informacje o stanowisku pracy można stwierdzić, że organizacja pracy nie jest dobrze dostosowana do możliwości psychofizycznych badanego pracownika. Wykonywanie powierzonych mu obowiązków może powodować nadmierne obciążenia pracą. Wskazana adaptacja stanowiska pracy bądź przekwalifikowanie zawodowe. Należy zwrócić uwagę na następujące aspekty sprzyjających zapewnieniu zdrowia i dobrej kondycji oraz jednocześnie dobrej efektywności pracy przez cały okres aktywności zawodowej."
  }

  throw new Error(`Unknown answer color: ${color}`);
}
