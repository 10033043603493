import {FC, useState} from 'react'
import {useExamQuestionCardStyles} from "./ExamQuestionCard.styles";
import {Box, Divider, Typography} from "@material-ui/core";
import removeOrphans from "../../../utils/removeOrphans";
import {DetailedQuestion, QuestionType} from "../examUtils/exam.types";
import ButtonsController from "../../formItems/buttonsController/ButtonsController.component";
import MultiselectButtonsController
  from "../../formItems/multiselectButtonsController/MultiselectButtonsController.component";
import {useFormContext} from "react-hook-form";
import AdditionalAnswerDescription from "../additionalAnswerDescription/AdditionalAnswerDescription.component";

interface ExamQuestionCardProps extends QuestionType {
  currentStep: number,
  userId: string,
}

const ExamQuestionCard: FC<ExamQuestionCardProps> = (
  {
    title,
    question,
    currentStep,
    code,
    answers,
    userId,
    detailedQuestions
  }) => {
  const classes = useExamQuestionCardStyles()
  const form = useFormContext()
  const [suggestedAnswer, setSuggestedAnswer] = useState<string |undefined>()

  let questionGroupCodes: DetailedQuestion[] = []
  if (detailedQuestions?.questions) questionGroupCodes = [...detailedQuestions.questions]
  if (detailedQuestions?.breakpoint) questionGroupCodes = [...questionGroupCodes, ...detailedQuestions.breakpoint.questions]

  const template: ({ code: string; scale: number; answer: string; })[] = questionGroupCodes
    .filter(item => !!item.scale)
    .map((item: DetailedQuestion) => ({
      code: item.code,
      scale: item.scale!,
      answer: form.getValues(item.code)
    }))

  const getSuggestedICFAnswer = (answerCode: string, value: string) => {
    const withAnswers = template?.map(item => item?.code && item.code === answerCode ? {...item, answer: value} : item)
    const isEveryItemFilled = withAnswers?.every(item => item?.answer !== undefined)

    if(isEveryItemFilled){
      const average = withAnswers.length !== 0 && withAnswers?.reduce((sum, val) => sum + (val?.scale && Number(val.answer) * val.scale), 0)
      const roundedAverage = average !== false && Math.round(average)
      setSuggestedAnswer(roundedAverage !== undefined ? roundedAverage.toString(): undefined)
    }
  }

  return(
    <div className={classes.root}>

      <Typography
        component={"p"}
        variant="h6"
      >
        {code}
      </Typography>

      <Typography
        component={"span"}
        variant="h5"
      >
        {title}
      </Typography>

      <Box className={classes.detailedQuestionsBox}>
        {detailedQuestions?.questions?.map(question => (
          <Box key={question.question}>
            <Typography
              component="p"
              className={classes.question}
            >
              {question.question}
            </Typography>
            <ButtonsController
              name={question.code}
              answers={question.answers}
              horizontal
              userId={userId}
              getSuggestedICFAnswer={getSuggestedICFAnswer}
            />
          </Box>
        ))}

        {detailedQuestions?.breakpoint &&
          <Box>
            <Typography
              variant={'h5'}
              component={"p"}
              className={classes.header}
            >
              {detailedQuestions.breakpoint.header}
            </Typography>

            {detailedQuestions.breakpoint.questions.map(question => (
              <Box key={question.question}>
                <Typography
                  component="p"
                  className={classes.question}
                >
                  {question.question}
                </Typography>
                <ButtonsController
                  name={question.code}
                  answers={question.answers}
                  horizontal
                  userId={userId}
                  getSuggestedICFAnswer={getSuggestedICFAnswer}
                />
              </Box>
            ))}
          </Box>
        }
      </Box>

      <Typography component={"p"} variant={"h4"} className={classes.header}>
        {code} {title}
      </Typography>

      <Typography
        component="p"
        className={classes.question}
        dangerouslySetInnerHTML={{__html: removeOrphans(question)}}
      />

      <Box>
        {currentStep === 0 && (
          <ButtonsController
            name={code}
            answers={answers}
            horizontal
            userId={userId}
            suggestedAnswer={suggestedAnswer}
          />
        )}

        {currentStep === 1 && (
          <Box>
              <ButtonsController
                name={code}
                answers={answers}
                horizontal
                userId={userId}
                suggestedAnswer={suggestedAnswer}
              />
          </Box>
        )}

        {currentStep === 2 &&(
          <MultiselectButtonsController
            name={code}
            answers={answers}
            horizontal
            userId={userId}
          />
        )}
      </Box>

      <AdditionalAnswerDescription
        code={code}
        userId={userId}
      />

      <Divider className={classes.divider}/>

    </div>
  )
}

export default ExamQuestionCard