import {makeStyles, createStyles, Theme} from '@material-ui/core/styles'

export const useAdditionalAnswerResultDescriptionStyles = makeStyles((theme: Theme) => createStyles({
  form: {
    display: "flex",
    margin: theme.spacing(1,0),
    alignItems: "flex-end"
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  descriptionBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: theme.spacing(2,0)
  },
  inputAdornment: {
    display: "flex",
    margin: theme.spacing("auto", 0 ,1)
  }
}))