import React, {FC, useState} from "react"
import {Box} from "@material-ui/core"
import {yupResolver} from "@hookform/resolvers/yup"
import Popup from "../../common/popup/Popup.component"
import {FormProvider, useForm} from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import {ADD_USER} from "./AddUser.mutation"
import {useMutation} from "@apollo/client";
import generateUuid from "../../../utils/generateUuid"
import {UserRole} from "../userRole/UserRole.types"
import {addUserValidationSchema} from "./AddUser.validation"
import {useSelector} from "react-redux"
import {selectCurrentTenantId, selectUserRole} from "../../../store/user/user.selectors"
import GlobalErrorSnackbar from "../../formItems/errors/globalErrorSnackbar/GlobalErrorSnackbar"
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component";
import UserRoleSelect from "../userRole/UserRoleSelect.component";
import TestedPersonOccupationSelect
  from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.component";
import TestedPersonsWorkplaceThreatsSelect
  from "../../testedPersons/testedPersonsWorkplaceThreatsSelect/TestedPersonsWorkplaceThreatsSelect.component";
import {TenantSelectItem} from "../../tenants/tenantsSelect/TenantsSelect.types";
import Grid from '@material-ui/core/Grid';
import AgeCategoryController from './ageCategoryController/AgeCategoryController';
import GenderController from './genderController/GenderController.component';
import MotivationController from './motivationController/MotivationController.component';
import SeniorityController from './seniorityController/SeniorityController.component';
import EducationController from './educationController/EducationController.component';
import WorkTypeController from './workTypeController/WorkTypeController.component';
import {GridSize} from "@material-ui/core/Grid/Grid";

interface AddUserPopupProps {
  open: boolean;
  refetch(): void;
  onClose(): void;
}

const AddUserPopup: FC<AddUserPopupProps> = ({open, refetch, onClose}) => {
  const currentUserRole = useSelector(selectUserRole)
  const tenantsId = useSelector(selectCurrentTenantId)
  const [addUser, {loading}] = useMutation(ADD_USER)
  const [createUserError, setCreateUserError] = useState<string>("")
  const isAdmin = currentUserRole === UserRole.ADMIN
  const isGuardian = currentUserRole === UserRole.GUARDIAN

  const form = useForm({
    mode: "all",
    resolver: yupResolver(addUserValidationSchema),
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      tenants: [],
      userRole: isGuardian ? UserRole.EXAMINED_PERSON : "",
      occupationTypeId: isAdmin ? undefined: "",
      workplaceThreats: isAdmin ? undefined : [],
      birthYear: isAdmin ? undefined : "",
      age: isAdmin ? undefined: "",
      ageCategoryId: isAdmin ? undefined : "",
      genderId: isAdmin ? undefined : "",
      motivation: isAdmin ? undefined : "",
      seniorityId: isAdmin ? undefined : "",
      educationId: isAdmin ? undefined : "",
      weigh: isAdmin ? undefined : "",
      height: isAdmin ? undefined : "",
      bmi: isAdmin ? undefined : "",
      workTypeId: isAdmin ? undefined : "",
    }
  })

  const roleValue = form.watch("userRole")
  const gridSize = {
    xs: 12 as GridSize,
    sm: !isAdmin ? 6 : 12 as GridSize,
    lg: !isAdmin ? 4 : 12 as GridSize,
  }

  const isTenantFieldRequired = isGuardian
    || (roleValue && roleValue === UserRole.GUARDIAN)

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    setCreateUserError("")
    try {
      await addUser({
        variables: {
          createUser: {
            ...values,
            birthYear: Number(values.birthYear),
            age: Number(values.age),
            motivation: Number(values.motivation),
            weigh: Number(values.weigh),
            height: Number(values.height),
            bmi: Number(values.bmi),
            userId: generateUuid(),
          },
          requestPasswordReset: {
            email: values.email
          }
        }
      })

      await refetch()
      handleClose()
    } catch (e: any) {
      if (e.graphQLErrors && e.graphQLErrors[0]?.message === 'duplicate key value violates unique constraint "users_email_key"') {
        form.setError("email", { message: "Użytkownik o podanym adresie e-mail już istnieje" })
      } else {
        setCreateUserError("Błąd rejestracji użytkownika")
      }
    }
  })

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title={isGuardian
        ? "Dodaj osobę badaną"
        : "Dodaj użytkownika"
      }
      actionButtonText={"Dodaj"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
      desktopWidth={isAdmin ? "800px" : "1200px"}
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item {...gridSize}>
                <TextFieldController
                  name="name"
                  label={'Imię'}
                  isRequired
                  trimStart
                  mb
                  disabled={loading}
                />
              </Grid>

              <Grid item {...gridSize}>
                <TextFieldController
                  name="surname"
                  label={'Nazwisko'}
                  isRequired
                  trimStart
                  mb
                  disabled={loading}
                />
              </Grid>

              <Grid item {...gridSize}>
                <TextFieldController
                  name="email"
                  label={'E-mail'}
                  isRequired
                  mb
                  disabled={loading}
                />
              </Grid>

              { isTenantFieldRequired &&
                <Grid item {...gridSize}>
                  <TenantsSelect
                    name="tenants"
                    label={'Podmiot'}
                    multiple={isAdmin}
                    filterBy={isGuardian
                      ? (tenant: TenantSelectItem) => {
                        return tenantsId.includes(tenant.tenantId)
                      }
                      : undefined
                    }
                    isRequired={isTenantFieldRequired}
                    mb
                    disabled={loading || !isTenantFieldRequired}
                  />
                </Grid>
              }

              {isGuardian && (
                <>
                  <Grid item {...gridSize}>
                    <TestedPersonOccupationSelect
                      name="occupationTypeId"
                      label={"Typ zawodu"}
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TestedPersonsWorkplaceThreatsSelect
                      name={'workplaceThreats'}
                      label={"Zagrożenia na stanowisku pracy"}
                      isRequired
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name="birthYear"
                      label={'Rok urodzenia'}
                      isRequired
                      mb
                      disabled={loading}
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name="age"
                      label={'Wiek'}
                      isRequired
                      mb
                      disabled={loading}
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <AgeCategoryController
                      name='ageCategoryId'
                      label='Kategoria wiekowa'
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <GenderController
                      name='genderId'
                      label='Płeć'
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <MotivationController
                      name='motivation'
                      label='Poziom motywacji do uczestnictwa'
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <SeniorityController
                      name='seniorityId'
                      label='Staż pracy w firmie'
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <EducationController
                      name='educationId'
                      label='Wykształcenie'
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name='weigh'
                      label='Masa ciała (kg)'
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name='height'
                      label='Wysokość (cm)'
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name='bmi'
                      label='BMI'
                      isRequired
                      mb
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <WorkTypeController
                      name='workTypeId'
                      label='Rodzaj pracy'
                      isRequired
                      mb
                    />
                  </Grid>
                </>
              )}
              {isAdmin && (
                <Grid item {...gridSize}>
                  <UserRoleSelect
                    name="userRole"
                    label={"Rola"}
                    isRequired
                    mb
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </FormProvider>

        <GlobalErrorSnackbar
          isError={!!createUserError}
          errorMessage={createUserError}
        />
      </Box>
    </Popup>
  )
}

export default AddUserPopup
