import {gql} from "@apollo/client"

export const ANSWERS_LIST = gql`
    query answersList($userId: UUID!){
      answersList: examAnswersListList(userId: $userId){
        answers
        category
        additionalInformation
      }
      user: userExam(userId: $userId) {
        userId
        fullName
        finishedExamAt
      }
    }
`