import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

const DRAWER_WIDTH = 240;

const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      position: "relative"
    },
    adminRoot: {
      display: "flex",
      minHeight: "100vh",
    },
    testedPersonLayout: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    appBar: {
      display: "flex",
      maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
      right: 0,
      color: theme.palette.common.white,
      position: "absolute"
    },
    topBar: {
      display: "flex",
      justifyContent: "flex-end"
    },
    topToolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    toolbarHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: "center",
      padding: theme.spacing(2),
      ...theme.mixins.toolbar,
      ...theme.typography.button,
      textTransform: "uppercase",
      letterSpacing: "1.4px",
    },
    adminContent: {
      minHeight: '100vh',
      overflow: 'auto',
      display: "flex",
      flexDirection: "column",
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: "auto",
      backgroundColor: theme.palette.background.default
    },
    appBarSpacer: theme.mixins.toolbar,
    contentContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }
    },
    adminContentContainer: {
      flex: 1,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: `${DRAWER_WIDTH}px`,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.white,
      boxShadow: theme.shadows["10"]
    },
    divider : {
      backgroundColor: theme.palette.secondary.main,
    }
  })
)

export default useLayoutStyles
