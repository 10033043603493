import React, { ChangeEvent, FC } from "react"
import { TextField } from "@material-ui/core"
import { Controller } from "react-hook-form"
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField"
import { useFormContext } from "react-hook-form"
import { useTextFieldControllerStyles } from "./TextFieldController.styles"
import clsx from "clsx"

export interface TextFieldControllerProps extends StandardTextFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  mb?: boolean;
  trimStart?: boolean;
  maxCharacters?: number;
  save?(): void;
}

const TextFieldController: FC<TextFieldControllerProps> = (
  {
    name,
    label,
    placeholder = label,
    isRequired = false,
    fullWidth = true,
    disabled = false,
    maxCharacters= 50,
    mb,
    trimStart,
    save,
    children,
    ...props
  }) => {
  const classes = useTextFieldControllerStyles()
  const form = useFormContext()

  const handleChange = (onChange: (value: string | null) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange(trimStart ? event.target.value.trimStart() : event.target.value)
    if (save) save()
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={(
        {
          field: {
            onChange,
            value
          },
          fieldState: {
            error,
            invalid,
          }
        }) => (
        <TextField
          value={value}
          label={isRequired ? label + " *" : label}
          placeholder={placeholder}
          disabled={disabled}
          error={invalid}
          helperText={error?.message}
          fullWidth={fullWidth}
          onChange={handleChange(onChange)}
          variant="outlined"
          inputProps={{maxLength: maxCharacters }}
          className={clsx(
            mb && classes.marginBottom
          )}
          {...props}
        >
          { children &&
            children
          }
        </TextField>
      )}
    />
  )
}

export default TextFieldController