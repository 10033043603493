import React, {FC, useEffect, useState} from 'react'
import {Controller, useFormContext} from "react-hook-form"
import {FormControl, Typography, Box} from "@material-ui/core";
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {AnswersType} from "../../examComponents/examUtils/exam.types";
import {useButtonsControllerStyles} from "./ButtonsController.styles";
import clsx from "clsx";
import {useMutation} from "@apollo/client";
import { SAVE_ANSWER } from "../../examComponents/examQuestionCard/ExamQuestionCard.mutation";
import {useDispatch} from "react-redux";
import {addSavingAnswer, removeSavingAnswer} from "../../../store/savingExamAnswer/savingExamAnswer.slice";

interface ButtonsControllerProps {
  name: string,
  answers: AnswersType[],
  horizontal: boolean,
  label?: string
  userId: string
  getSuggestedICFAnswer?: (code: string, value: string) => void
  suggestedAnswer?: string
}

const ButtonsController: FC<ButtonsControllerProps> = ({userId, name, answers, horizontal, label,getSuggestedICFAnswer,suggestedAnswer}) => {
  const form = useFormContext()
  const classes = useButtonsControllerStyles()
  const dispatch = useDispatch()
  const [saveAnswer,{loading}] = useMutation(SAVE_ANSWER)
  const [isChoosedAnswer, setIsChoosedAnswer] = useState(false)

  const item = form.getValues(name)

  useEffect(() => {
    if(loading) {
      dispatch(addSavingAnswer(name))
    } else {
      dispatch(removeSavingAnswer(name))
    }
    getSuggestedICFAnswer && getSuggestedICFAnswer(name, item)
    item && setIsChoosedAnswer(true)
  },[loading, item])

  const sendAnswer = async (value: string, code: string) => {
    try {
      await saveAnswer({
        variables: {
          answers: [value],
          category: code,
          userId
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Controller
      name={name}
      control={form.control}
      rules={{required: true}}
      render={(
        {
          field: {
            onChange,
            value
          },
          fieldState: {
            error,
            invalid
          }
        }) => (
        <FormControl
          component="fieldset"
          error={invalid}
        >
          <>
            <Box className={clsx(classes.container, label && classes.marginTop)}>
              {label && (
                <Typography
                  variant={"body1"}
                  className={classes.label}
                >
                  {label}
                </Typography>
              )}

               <ToggleButtonGroup
                 id={name}
                 color="secondary"
                 value={value}
                 onChange={(e, value) => {
                   onChange(value)
                 }}
                 className={error && classes.errorContainer}
                 exclusive
                 orientation={horizontal ? "horizontal" : "vertical"}
               >
                 {answers.map(answer => (
                   <ToggleButton
                     value={answer.label}
                     key={answer.value}
                     className={clsx(
                       classes.button,
                       answer.value == value && classes.selectedAnswerButton,
                       (suggestedAnswer === answer.label && !isChoosedAnswer) && classes.suggestedAnswerButton
                     )}
                     disabled={loading}
                     onClick={() => {
                       ((!loading && answer.value != value) && sendAnswer(answer.label, name))
                       setIsChoosedAnswer(true)
                     }
                   }
                   >
                     {answer.label}
                     <br/>
                     {answer.description}

                     {suggestedAnswer && !isChoosedAnswer && suggestedAnswer === answer.label && (
                       <p className={classes.suggestedAnswerText}>
                         Sugerowana odpowiedź
                       </p>
                     )}
                   </ToggleButton>
                 ))}
               </ToggleButtonGroup>
            </Box>

            {error && (
              <Typography
                variant={"subtitle1"}
                className={classes.errorText}
              >
                Odpowiedź jest wymagana
              </Typography>
            )}
          </>
        </FormControl>
      )}
    />
  )
}
export default ButtonsController