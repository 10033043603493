import React, {FC, useEffect, useState} from 'react'
import ExamPageCard from '../components/examComponents/examPageCard/ExamPageCard.component'
import {useHistory, useParams} from 'react-router'
import {useMutation, useQuery} from '@apollo/client'
import {useForm, FormProvider} from "react-hook-form";
import {Box, Button, CircularProgress, Fade, Typography} from "@material-ui/core";
import {scrollToElementId, scrollToTop} from "../utils/scrollTo";
import ExamQuestionCard from "../components/examComponents/examQuestionCard/ExamQuestionCard.component";
import {EXAM_QUESTIONS} from "../components/examComponents/examUtils/examQuestions.utils";
import {FINISH_EXAM} from "../components/examComponents/examQuestionCard/ExamQuestionCard.mutation";
import {ANSWERS_LIST} from "../components/examComponents/examQuestionCard/ExamQuestionCard.query";
import LoaderBox from "../components/common/loaderBox/LoaderBox.component";
import {AnswersList} from "../components/examComponents/examUtils/exam.types";
import {useExamQuestionCardStyles} from "../components/examComponents/examQuestionCard/ExamQuestionCard.styles";
import {isSavingAnswers} from "../store/savingExamAnswer/savingExamAnswer.selectors";
import {useSelector} from "react-redux";
import GlobalSuccessSnackbar from "../components/formItems/globalSuccessSnackbar/GlobalSuccessSnackbar";
import {RoutePath} from "../routes/Routes.types";
import {isIdValidated} from "../utils/validateId";
import {selectUserRole} from "../store/user/user.selectors";
import FinishedExamModal from "../components/examComponents/finishedExamModal/FinishedExamModal.component";
import {Redirect} from "react-router-dom"
import ExamProgressBar from "../components/examComponents/examProgressBar/ExamProgressBar.component";

const ExamPage: FC = () => {
  const [editMode, setEditMode] = useState(false)
  const [openFinishModal,setOpenFinishModal] = useState(false)
  const [currentSection, setCurrentSection] = useState(0)
  const [isSuccessSaved, setIsSuccessSaved] = useState<boolean>(false)
  const examSectionsStartQuestionIndex: number[] = [0,11,25]
  const classes = useExamQuestionCardStyles()
  const {push} = useHistory()

  const isSavingAnswer = useSelector(isSavingAnswers)
  const currentUser = useSelector(selectUserRole)

  const form = useForm()
  const { id } = useParams<{ id: string }>()

  const [finishExam, {loading: finishExamLoading}] = useMutation(FINISH_EXAM)

  const { data, loading } = useQuery<AnswersList>(ANSWERS_LIST,{
    variables: {
      userId: id
    },
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    // get initial answers values
    if (!loading) {
      const formValues = data!.answersList.reduce((
        obj: {
          [key: string]:
            string  // answer for not "E" category
            | string[] // answer for "E" category
            | {[key: string]: string | null} // additionalInformation
        },
        currentValue
      ) => {
        const {category, answers, additionalInformation} = currentValue

        obj[category] = category[0] === "E"
          ? answers // multiple-answer question
          : answers[0] // single-answer question

        return {
          ...obj,
          additionalInformation: {
            ...obj.additionalInformation as object,
            [category]: additionalInformation
          }
        }
      }, {
        additionalInformation: {}
      })

      form.reset(formValues)

      setEditMode(!!data?.user?.finishedExamAt)
    }
  },[loading, id])

  if (!id
    || !isIdValidated(id)
    || (data && !data?.user?.userId)
    || (!currentUser && data?.user?.finishedExamAt)
  ) {
    return <Redirect to={RoutePath.ERROR_404}/>
  }

  const scrollToFirstError = () => {
    const {errors} = form.formState
    const arrayFromErrors = Object.values(errors)
    if (arrayFromErrors.length) {
      scrollToElementId(arrayFromErrors[0].ref.name, -150)
    }
  }

  const handleSubmit = form.handleSubmit(async() => {
    setIsSuccessSaved(false)

    try {
      if(!editMode) {
        await finishExam({
          variables: {
            userId: id
          }
        })
      }
      setIsSuccessSaved(true)

      currentUser
        ? push(RoutePath.TESTED_PERSONS)
        : setOpenFinishModal(true)
    } catch (e){
      console.error(e)
    }
  }, async() => {
    scrollToFirstError()
  })

  const triggerErrorsOnNextPageClick = async() => {
    const isFormValidate = await form.trigger()

    if (isFormValidate) {
      setCurrentSection(sectionNumber => sectionNumber + 1)
      scrollToTop()
    } else {
      scrollToFirstError()
    }
  }

  const loadingBox = (text: string) => (
    <div className={classes.loaderBox}>
      <CircularProgress size={12} thickness={7} color="inherit"/>

      <Typography className={classes.loaderText} variant={"body2"}>
        { text }
      </Typography>
    </div>
  )

  return (
    <ExamPageCard
      fullName={data?.user?.fullName || ""}
      exam
    >
      {loading
        ? <LoaderBox/>
        : <FormProvider {...form}>
            <form onSubmit={handleSubmit} className={finishExamLoading ? classes.disabledForm: ""}>
              <ExamProgressBar
                currentSectionNumber={currentSection + 1}
                sectionsAmount={examSectionsStartQuestionIndex.length}
              />

              {EXAM_QUESTIONS
                .slice(
                  examSectionsStartQuestionIndex[currentSection],
                  currentSection === examSectionsStartQuestionIndex.length -1
                    ? EXAM_QUESTIONS.length
                    : examSectionsStartQuestionIndex[currentSection + 1]
                )
                .map(question => (
                  <ExamQuestionCard
                    key={question.id}
                    currentStep={currentSection}
                    userId={data!.user.userId}
                    {...question}
                  />
              ))}

              <Fade in={isSavingAnswer || finishExamLoading} timeout={300}>
                <Box className={classes.loaderBoxWrapper}>
                  { isSavingAnswer && loadingBox("Trwa zapisywanie odpowiedzi")}
                  { finishExamLoading && loadingBox("Trwa zapisywanie badania")}
                  {" "}
                </Box>
              </Fade>

              <Box display={"flex"} justifyContent={currentSection === 0 ? "flex-end" : "space-between"} width={"100%"}>
                {currentSection !== 0 &&
                  <Button
                    type="button"
                    variant={"outlined"}
                    color={"primary"}
                    disabled={isSavingAnswer}
                    onClick={() => setCurrentSection(sectionNumber => sectionNumber - 1)}
                  >
                    Poprzednia strona
                  </Button>
                }

                <div>
                  {editMode &&
                    <Button
                      type="button"
                      variant={currentSection === examSectionsStartQuestionIndex.length - 1
                        ? "contained"
                        : "outlined"
                    }
                      color={"primary"}
                      disabled={isSavingAnswer}
                      onClick={() => push(RoutePath.TESTED_PERSONS)}
                    >
                      Zamknij
                    </Button>
                  }
                  {currentSection !== examSectionsStartQuestionIndex.length - 1 &&
                    <Button
                      type="button"
                      variant={"contained"}
                      color={"primary"}
                      disabled={isSavingAnswer}
                      className={classes.nextPageButton}
                      onClick={triggerErrorsOnNextPageClick}
                    >
                      Następna strona
                    </Button>
                  }
                </div>

                {currentSection === examSectionsStartQuestionIndex.length -1 && !editMode &&
                  <Button
                    type="submit"
                    variant={"contained"}
                    color={"primary"}
                    disabled={finishExamLoading || isSavingAnswer}
                  >
                    Zakończ badanie
                  </Button>
                }

              </Box>
            </form>
          </FormProvider>
        }
        <GlobalSuccessSnackbar
          isSuccess={isSuccessSaved}
          successMessage={"Zapisano badanie"}
        />
      <FinishedExamModal
        open={openFinishModal}
      />
    </ExamPageCard>
  )
}

export default ExamPage