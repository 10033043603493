import {FC} from "react"
import {BookType} from "../examUtils/exam.types";
import {useBookItemStyles} from "./BookItem.styles";

interface BookItemProps extends BookType{}

const BookItem: FC<BookItemProps> = ({title, author, link}) => {
  const styles = useBookItemStyles()

  return(
    <li className={styles.root}>
      {link
        ? <a href={link} target="_blank" rel="noreferrer noopener">{title}</a>
        : <span>{title}</span>
      }
      <span> - {author}</span>
    </li>
  )
}

export default BookItem