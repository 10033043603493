import {FC} from 'react'
import {Box, BoxProps, Divider, Typography} from "@material-ui/core";
import {useBoxWithDividerStyles} from "./BoxWithDivider.styles";
import clsx from "clsx";

interface BoxWithDividerProps extends BoxProps {
  title: string;
}

const BoxWithDivider: FC<BoxWithDividerProps> = (
  {
    title,
    children,
    ...props
  }
) => {
  const classes = useBoxWithDividerStyles()

  return(
    <Box {...props}>
      <Divider className={clsx(classes.divider, "print-hidden")}/>

      <Typography
        variant={"h5"}
        component={"span"}
        className={"print-font-weight-normal"}
      >
        {title}
      </Typography>

      {children}
    </Box>
  )
}

export default BoxWithDivider