import {ExamResultTypes} from "../../testedPersons/examsResults/ExamsResult.types";
import {AdvisoryActivitiesType} from "./exam.types";

export const advisoryActivities: AdvisoryActivitiesType[] = [
  {
    code: "B130",
    books: [
      {
        title: "Jak zadbać o kondycję fizyczną w pracy umysłowej",
        author: "Kamińska Łach Sumińska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/89522/Materialy-informacyjne-I-N-02-J-Kaminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Warunki i organizacja pracy dla osób z cukrzycą",
        author: "M. Bernas J. Bugajska E. Łastowiecka-Moras",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/91767/Warunki-i-organizacja-pracy-cukrzyca-CIOP-PIB-2016.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS,ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Stereotypy związane z wiekiem a funkcjonowanie zawodowe pracowników w wieku 50+",
        author: "Mockałło",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/83912/Stereotypy-50-plus-Z-Mockallo-I-P-17.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Wymagania w pracy - wyzwania czy przeszkody",
        author: "Mockałło Widerszal-Bazyl",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/89484/Materialy-informacyjne-I-N-04-Z-Mockallo-.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK SOBIE RADZIĆ Z OBCIĄŻENIEM PRACĄ ZMIANOWĄ, JAK POPRAWIĆ SPRAWNOŚĆ POZNAWCZĄ I ODPOCZYWAĆ EFEKTYWNIE",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88341/2019100332151&Materialy-informacyjne-pracownik-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK ZWIĘKSZYĆ SPRAWNOŚĆ POZNAWCZĄ I ZMNIEJSZYĆ OBCIĄŻENIE PRACĄ ZMIANOWĄ. Poradnik",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Obciążenie pracą zmianową a efektywność poznawcza pracowników. Materiały szkoleniowe dla kadry kierowniczej i służb BHP. Materiały informacyjne",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/88340/2019100331748&Materialy-informacyjne-kadra-kierownicza-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Obciążenie wynikające z presji czasu - ocena i profilaktyka",
        author: "Kamińska, Najmiec",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "B134",
    books: [
      {
        title: "Praca zmianowa a funkcjonowanie systemu nerwowo-mięśniowego",
        author: "Łach Mazur-Różycka",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK SOBIE RADZIĆ Z OBCIĄŻENIEM PRACĄ ZMIANOWĄ, JAK POPRAWIĆ SPRAWNOŚĆ POZNAWCZĄ I ODPOCZYWAĆ EFEKTYWNIE",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88341/2019100332151&Materialy-informacyjne-pracownik-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK ZWIĘKSZYĆ SPRAWNOŚĆ POZNAWCZĄ I ZMNIEJSZYĆ OBCIĄŻENIE PRACĄ ZMIANOWĄ. Poradnik",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Obciążenie pracą zmianową a efektywność poznawcza pracowników. Materiały szkoleniowe dla kadry kierowniczej i służb BHP. Materiały informacyjne",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/88340/2019100331748&Materialy-informacyjne-kadra-kierownicza-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
    ]
  },
  {
    code: "B140",
    books: [
      {
        title: "Praca zmianowa a funkcjonowanie systemu nerwowo-mięśniowego",
        author: "Łach Mazur-Różycka",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK SOBIE RADZIĆ Z OBCIĄŻENIEM PRACĄ ZMIANOWĄ, JAK POPRAWIĆ SPRAWNOŚĆ POZNAWCZĄ I ODPOCZYWAĆ EFEKTYWNIE",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88341/2019100332151&Materialy-informacyjne-pracownik-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK ZWIĘKSZYĆ SPRAWNOŚĆ POZNAWCZĄ I ZMNIEJSZYĆ OBCIĄŻENIE PRACĄ ZMIANOWĄ. Poradnik",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Obciążenie pracą zmianową a efektywność poznawcza pracowników. Materiały szkoleniowe dla kadry kierowniczej i służb BHP. Materiały informacyjne",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/88340/2019100331748&Materialy-informacyjne-kadra-kierownicza-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Obciążenie wynikające z presji czasu - ocena i profilaktyka",
        author: "Kamińska, Najmiec",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Jak radzić sobie ZE STRESEM ZAWODOWYM – trening oparty na uważności",
        author: "Dorota Żołnierczyk-Zreda",
        link: "https://www.zus.pl/documents/10182/167570/Jak_radzic_sobie_ze_stresem_zawodowym_poradnik.pdf/7bba9075-8734-4d3f-a0f3-a6f89aa2509c",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "B144",
    books: [
      {
        title: "JAK SOBIE RADZIĆ Z OBCIĄŻENIEM PRACĄ ZMIANOWĄ, JAK POPRAWIĆ SPRAWNOŚĆ POZNAWCZĄ I ODPOCZYWAĆ EFEKTYWNIE",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88341/2019100332151&Materialy-informacyjne-pracownik-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK ZWIĘKSZYĆ SPRAWNOŚĆ POZNAWCZĄ I ZMNIEJSZYĆ OBCIĄŻENIE PRACĄ ZMIANOWĄ. Poradnik",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Obciążenie pracą zmianową a efektywność poznawcza pracowników. Materiały szkoleniowe dla kadry kierowniczej i służb BHP. Materiały informacyjne",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/88340/2019100331748&Materialy-informacyjne-kadra-kierownicza-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
    ]
  },
  {
    code: "B152",
    books: [
      {
        title: "Jak zadbać o kondycję w pracy umysłowej",
        author: "Kamińska Sumińska Nowak",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/89523/Poradnik-I-N-02-J-Kaminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Stereotypy związane z wiekiem a funkcjonowanie zawodowe pracowników w wieku 50+",
        author: "Mockałło",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/83912/Stereotypy-50-plus-Z-Mockallo-I-P-17.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "JAK SOBIE RADZIĆ Z OBCIĄŻENIEM PRACĄ ZMIANOWĄ, JAK POPRAWIĆ SPRAWNOŚĆ POZNAWCZĄ I ODPOCZYWAĆ EFEKTYWNIE",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88341/2019100332151&Materialy-informacyjne-pracownik-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK ZWIĘKSZYĆ SPRAWNOŚĆ POZNAWCZĄ I ZMNIEJSZYĆ OBCIĄŻENIE PRACĄ ZMIANOWĄ. Poradnik",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Obciążenie pracą zmianową a efektywność poznawcza pracowników. Materiały szkoleniowe dla kadry kierowniczej i służb BHP. Materiały informacyjne",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/88340/2019100331748&Materialy-informacyjne-kadra-kierownicza-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Warunki i organizacja pracy dla osób z chorobami układu sercowo-naczyniowego",
        author: "Bugajska, Tyszkiewicz",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/91766/2021021741812&Warunki-i-organizacja-pracy-choroby-ukladu-sercowo-naczyniowego-CIOP-PIB-2016.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Jak radzić sobie ZE STRESEM ZAWODOWYM – trening oparty na uważności",
        author: "Dorota Żołnierczyk-Zreda",
        link: "https://www.zus.pl/documents/10182/167570/Jak_radzic_sobie_ze_stresem_zawodowym_poradnik.pdf/7bba9075-8734-4d3f-a0f3-a6f89aa2509c",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "B280",
    books: [
      {
        title: "Jak zadbać o kondycję fizyczną w pracy umysłowej",
        author: "Kamińska Łach Sumińska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/89522/Materialy-informacyjne-I-N-02-J-Kaminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Propiocepcja i równowaga ciała - minimalizowanie ryzyka upadku",
        author: "Mazur-Różycka Łach",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88662/2019103102928&Materialy-informacyjne-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Ograniczenie dolegliwości układu mięśniowo-szkieletowego osób niepełnosprawnych na stanowiskach montazystów i operatorów",
        author: "Tokarski",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "PROGRAM ĆWICZEŃ MAJĄCYCH NA CELU POPRAWĘ ZDOLNOŚCI KOORDYNACYJNYCH I SAMOKONTROLI POŁOŻENIA CIAŁA W PRZESTRZENI",
        author: "Łach, Mazur-Rózycka",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88663/2019103103051&Program-cwiczen-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Dostosowanie stanowisk pracy do możliwości fizycznych starszych pracowników",
        author: "Roman-Liu",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "B420",
    books: [
      {
        title: "Zagrożenia związane z pracą kobiet 55+ na stanowiskach pracy fizycznej w handlu",
        author: "Łastowiecka-Moras E.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88301/2019092705755&Materialy-informacyjne-broszura-2-G-10-E-Lastowiecka-2019.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Warunki i organizacja pracy dla osób z cukrzycą.",
        author: "M. Bernas J. Bugajska E. Łastowiecka-Moras",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/91767/Warunki-i-organizacja-pracy-cukrzyca-CIOP-PIB-2016.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Pracownik starszy a obciążenie cieplne",
        author: "Malińska M., Młynarczyk M.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/89415/Ulotka-2-G-08-2019-M-Mlynarczyk.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Warunki i organizacja pracy dla osób z chorobami układu sercowo-naczyniowego",
        author: "Bugajska, Tyszkiewicz",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/91766/2021021741812&Warunki-i-organizacja-pracy-choroby-ukladu-sercowo-naczyniowego-CIOP-PIB-2016.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
    ]
  },
  {
    code: "B455",
    books: [
      {
        title: "Zagrożenia związane z pracą kobiet 55+ na stanowiskach pracy fizycznej w handlu",
        author: "Łastowiecka-Moras E.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88301/2019092705755&Materialy-informacyjne-broszura-2-G-10-E-Lastowiecka-2019.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Warunki i organizacja pracy dla osób z cukrzycą",
        author: "M. Bernas J. Bugajska E. Łastowiecka-Moras",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/91767/Warunki-i-organizacja-pracy-cukrzyca-CIOP-PIB-2016.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS,ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "PROGRAM ĆWICZEŃ MAJĄCYCH NA CELU POPRAWĘ ZDOLNOŚCI KOORDYNACYJNYCH I SAMOKONTROLI POŁOŻENIA CIAŁA W PRZESTRZENI",
        author: "Łach, Mazur-Rózycka",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88663/2019103103051&Program-cwiczen-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Pracownik starszy a obciążenie cieplne",
        author: "Malińska M., Młynarczyk M.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/89415/Ulotka-2-G-08-2019-M-Mlynarczyk.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Warunki i organizacja pracy dla osób z chorobami układu sercowo-naczyniowego",
        author: "Bugajska, Tyszkiewicz",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/91766/2021021741812&Warunki-i-organizacja-pracy-choroby-ukladu-sercowo-naczyniowego-CIOP-PIB-2016.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
    ]
  },
  {
    code: "B710",
    books: [
      {
        title: "Zapobieganie dolegliwościom mięśniowo-szkieletowym pracowników biurowych. Kompleksowy program interwencji profilaktycznej",
        author: "Malińska M.",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Propiocepcja i równowaga ciała - minimalizowanie ryzyka upadku",
        author: "Mazur-Różycka Łach",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88662/2019103102928&Materialy-informacyjne-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "ZESTAW ĆWICZEŃ POPRAWIAJĄCYCH ZDOLNOŚĆ UTRZYMANIA RÓWNOWAGI",
        author: "Tokarski",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/89417/Tomasz-Tokarski-broszura-I-N-12.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "PROGRAM ĆWICZEŃ MAJĄCYCH NA CELU POPRAWĘ ZDOLNOŚCI KOORDYNACYJNYCH I SAMOKONTROLI POŁOŻENIA CIAŁA W PRZESTRZENI",
        author: "Łach, Mazur-Rózycka",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88663/2019103103051&Program-cwiczen-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Sposoby ograniczenia obciążenia i zmęczenia na stanowiskach pracy powtarzalnej (zalecenia i przykłady dobrych praktyk)",
        author: "Łach P.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Dostosowanie stanowisk pracy do możliwości fizycznych starszych pracowników",
        author: "Roman-Liu",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Zalecenia dotyczące stylu życia, warunków i organizacji pracy dla osób z chorobą zwyrodnieniową stawów",
        author: "Bugajska, Księżopolska-Orłowska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/91765/2021021741645&Warunki-i-organizacja-pracy-choroba-zwyrodnieniowa-stawow-CIOP-PIB-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "B730",
    books: [
      {
        title: "Zagrożenia związane z pracą kobiet 55+ na stanowiskach pracy fizycznej w handlu",
        author: "Łastowiecka-Moras E.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88301/2019092705755&Materialy-informacyjne-broszura-2-G-10-E-Lastowiecka-2019.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Zapobieganie dolegliwościom mięśniowo-szkieletowym pracowników biurowych. Kompleksowy program interwencji profilaktycznej",
        author: "Malińska M.",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Propiocepcja i równowaga ciała - minimalizowanie ryzyka upadku",
        author: "Mazur-Różycka Łach",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88662/2019103102928&Materialy-informacyjne-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "ZESTAW ĆWICZEŃ POPRAWIAJĄCYCH ZDOLNOŚĆ UTRZYMANIA RÓWNOWAGI",
        author: "Tokarski",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/89417/Tomasz-Tokarski-broszura-I-N-12.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "PROGRAM ĆWICZEŃ MAJĄCYCH NA CELU POPRAWĘ ZDOLNOŚCI KOORDYNACYJNYCH I SAMOKONTROLI POŁOŻENIA CIAŁA W PRZESTRZENI",
        author: "Łach, Mazur-Rózycka",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88663/2019103103051&Program-cwiczen-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Dostosowanie stanowisk pracy do możliwości fizycznych starszych pracowników",
        author: "Roman-Liu",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "D110",
    books: [
      {
        title: "DLACZEGO WARTO ZATRUDNIAĆ OSOBY Z DYSFUNKCJĄ WZROKU?Poradnik dla pracodawcy",
        author: "Polski Związek niewidowmych",
        link: "http://nowa.pzn.org.pl/wp-content/uploads/2015/09/PRACODAWCY_BROSZURA.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      }
    ]
  },
  {
    code: "D160",
    books: [
      {
        title: "Jak zadbać o kondycję w pracy umysłowej",
        author: "Kamińska Sumińska Nowak",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/89523/Poradnik-I-N-02-J-Kaminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK SOBIE RADZIĆ Z OBCIĄŻENIEM PRACĄ ZMIANOWĄ, JAK POPRAWIĆ SPRAWNOŚĆ POZNAWCZĄ I ODPOCZYWAĆ EFEKTYWNIE",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88341/2019100332151&Materialy-informacyjne-pracownik-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK ZWIĘKSZYĆ SPRAWNOŚĆ POZNAWCZĄ I ZMNIEJSZYĆ OBCIĄŻENIE PRACĄ ZMIANOWĄ. Poradnik",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Obciążenie pracą zmianową a efektywność poznawcza pracowników. Materiały szkoleniowe dla kadry kierowniczej i służb BHP. Materiały informacyjne",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/88340/2019100331748&Materialy-informacyjne-kadra-kierownicza-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Jak radzić sobie ZE STRESEM ZAWODOWYM – trening oparty na uważności",
        author: "Dorota Żołnierczyk-Zreda",
        link: "https://www.zus.pl/documents/10182/167570/Jak_radzic_sobie_ze_stresem_zawodowym_poradnik.pdf/7bba9075-8734-4d3f-a0f3-a6f89aa2509c",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "D175",
    books: [
      {
        title: "Jak radzić sobie ZE STRESEM ZAWODOWYM – trening oparty na uważności",
        author: "Dorota Żołnierczyk-Zreda",
        link: "https://www.zus.pl/documents/10182/167570/Jak_radzic_sobie_ze_stresem_zawodowym_poradnik.pdf/7bba9075-8734-4d3f-a0f3-a6f89aa2509c",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Wytyczne dla pracodawców dotyczące kształtowania właściwych psychospołecznych warunków pracy dla osób z depresją",
        author: "Żołnierczyk-Zreda D",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Program treningu uważnościz elementami terapii poznawczej dla osób z depresją",
        author: "Żołnierczyk-Zreda D",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "D177",
    books: [
      {
        title: "Jak radzić sobie ZE STRESEM ZAWODOWYM – trening oparty na uważności",
        author: "Dorota Żołnierczyk-Zreda",
        link: "https://www.zus.pl/documents/10182/167570/Jak_radzic_sobie_ze_stresem_zawodowym_poradnik.pdf/7bba9075-8734-4d3f-a0f3-a6f89aa2509c",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Wytyczne dla pracodawców dotyczące kształtowania właściwych psychospołecznych warunków pracy dla osób z depresją",
        author: "Żołnierczyk-Zreda D",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Program treningu uważnościz elementami terapii poznawczej dla osób z depresją",
        author: "Żołnierczyk-Zreda D",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "D240",
    books: [
      {
        title: "Jak zadbać o kondycję w pracy umysłowej",
        author: "Kamińska Sumińska Nowak",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/89523/Poradnik-I-N-02-J-Kaminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "DLACZEGO WARTO ZATRUDNIAĆ OSOBY Z DYSFUNKCJĄ WZROKU?Poradnik dla pracodawcy",
        author: "Polski Związek niewidowmych",
        link: "http://nowa.pzn.org.pl/wp-content/uploads/2015/09/PRACODAWCY_BROSZURA.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Warunki i organizacja pracy dla osób z cukrzycą.",
        author: "M. Bernas J. Bugajska E. Łastowiecka-Moras",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/91767/Warunki-i-organizacja-pracy-cukrzyca-CIOP-PIB-2016.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK SOBIE RADZIĆ Z OBCIĄŻENIEM PRACĄ ZMIANOWĄ, JAK POPRAWIĆ SPRAWNOŚĆ POZNAWCZĄ I ODPOCZYWAĆ EFEKTYWNIE",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88341/2019100332151&Materialy-informacyjne-pracownik-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "JAK ZWIĘKSZYĆ SPRAWNOŚĆ POZNAWCZĄ I ZMNIEJSZYĆ OBCIĄŻENIE PRACĄ ZMIANOWĄ. Poradnik",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Obciążenie pracą zmianową a efektywność poznawcza pracowników. Materiały szkoleniowe dla kadry kierowniczej i służb BHP. Materiały informacyjne",
        author: "Sumińska, Nowak, Łukomska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/88340/2019100331748&Materialy-informacyjne-kadra-kierownicza-I-N-03-S-Suminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT]
      },
      {
        title: "Jak radzić sobie ZE STRESEM ZAWODOWYM – trening oparty na uważności",
        author: "Dorota Żołnierczyk-Zreda",
        link: "https://www.zus.pl/documents/10182/167570/Jak_radzic_sobie_ze_stresem_zawodowym_poradnik.pdf/7bba9075-8734-4d3f-a0f3-a6f89aa2509c",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Wytyczne dla pracodawców dotyczące kształtowania właściwych psychospołecznych warunków pracy dla osób z depresją",
        author: "Żołnierczyk-Zreda D",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Program treningu uważnościz elementami terapii poznawczej dla osób z depresją",
        author: "Żołnierczyk-Zreda D",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "D415",
    books: [
      {
        title: "Jak zadbać o kondycję fizyczną w pracy umysłowej",
        author: "Kamińska Łach Sumińska",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/89522/Materialy-informacyjne-I-N-02-J-Kaminska.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Profilaktyka przewlekłej niewydolności żylnej kończyn dolnych wśród osób wykonujących pracę w pozycji siedzącej i stojącej",
        author: "Łastowiecka-Morsa",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/79745/Profilaktyka-przewleklej-niewydolnosci-zylnej-mat-informacyjne-2016.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Propiocepcja i równowaga ciała - minimalizowanie ryzyka upadku",
        author: "Mazur-Różycka Łach",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88662/2019103102928&Materialy-informacyjne-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Ograniczenie dolegliwości układu mięśniowo-szkieletowego osób niepełnosprawnych na stanowiskach montazystów i operatorów",
        author: "Tokarski",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "ZESTAW ĆWICZEŃ POPRAWIAJĄCYCH ZDOLNOŚĆ UTRZYMANIA RÓWNOWAGI",
        author: "Tokarski",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/89417/Tomasz-Tokarski-broszura-I-N-12.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "PROGRAM ĆWICZEŃ MAJĄCYCH NA CELU POPRAWĘ ZDOLNOŚCI KOORDYNACYJNYCH I SAMOKONTROLI POŁOŻENIA CIAŁA W PRZESTRZENI",
        author: "Łach, Mazur-Rózycka",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88663/2019103103051&Program-cwiczen-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Sposoby ograniczenia obciążenia i zmęczenia na stanowiskach pracy powtarzalnej (zalecenia i przykłady dobrych praktyk)",
        author: "Łach P.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
    ]
  },
  {
    code: "D430",
    books: [
      {
        title: "Dostosowanie stanowisk pracy do możliwości fizycznych starszych pracowników",
        author: "Roman-Liu",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "D440",
    books: [
      {
        title: "Sposoby ograniczenia obciążenia i zmęczenia na stanowiskach pracy powtarzalnej (zalecenia i przykłady dobrych praktyk)",
        author: "Łach P.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Dostosowanie stanowisk pracy do możliwości fizycznych starszych pracowników",
        author: "Roman-Liu",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Zalecenia dotyczące stylu życia, warunków i organizacji pracy dla osób z chorobą zwyrodnieniową stawów",
        author: "Bugajska, Księżopolska-Orłowska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/91765/2021021741645&Warunki-i-organizacja-pracy-choroba-zwyrodnieniowa-stawow-CIOP-PIB-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "D445",
    books: [
      {
        title: "Sposoby ograniczenia obciążenia i zmęczenia na stanowiskach pracy powtarzalnej (zalecenia i przykłady dobrych praktyk)",
        author: "Łach P.",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Dostosowanie stanowisk pracy do możliwości fizycznych starszych pracowników",
        author: "Roman-Liu",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Zalecenia dotyczące stylu życia, warunków i organizacji pracy dla osób z chorobą zwyrodnieniową stawów",
        author: "Bugajska, Księżopolska-Orłowska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/91765/2021021741645&Warunki-i-organizacja-pracy-choroba-zwyrodnieniowa-stawow-CIOP-PIB-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      }
    ]
  },
  {
    code: "D450",
    books: [
      {
        title: "Propiocepcja i równowaga ciała - minimalizowanie ryzyka upadku",
        author: "Mazur-Różycka Łach",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88662/2019103102928&Materialy-informacyjne-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "ZESTAW ĆWICZEŃ POPRAWIAJĄCYCH ZDOLNOŚĆ UTRZYMANIA RÓWNOWAGI",
        author: "Tokarski",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/89417/Tomasz-Tokarski-broszura-I-N-12.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "PROGRAM ĆWICZEŃ MAJĄCYCH NA CELU POPRAWĘ ZDOLNOŚCI KOORDYNACYJNYCH I SAMOKONTROLI POŁOŻENIA CIAŁA W PRZESTRZENI",
        author: "Łach, Mazur-Rózycka",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/88663/2019103103051&Program-cwiczen-I-N-11-J-Mazur-Rozycka.pdf",
        resultType: [ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
      {
        title: "Zalecenia dotyczące stylu życia, warunków i organizacji pracy dla osób z chorobą zwyrodnieniową stawów",
        author: "Bugajska, Księżopolska-Orłowska",
        link: "https://www.ciop.pl/CIOPPortalWAR/file/91765/2021021741645&Warunki-i-organizacja-pracy-choroba-zwyrodnieniowa-stawow-CIOP-PIB-2017.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
    ]
  },
  {
    code: "D720",
    books: [
      {
        title: "Jak zadbać o kondycję w pracy umysłowej",
        author: "Kamińska Sumińska Nowak",
        link: "https://m.ciop.pl/CIOPPortalWAR/file/89523/Poradnik-I-N-02-J-Kaminska.pdf",
        resultType: [ExamResultTypes.GUARDIAN_RESULT, ExamResultTypes.EXAMINED_PERSON_RESULTS]
      },
    ]
  }
]