import {
  WorkplaceThreatsTypes
} from "../../testedPersons/testedPersonsWorkplaceThreatsSelect/TestedPersonsWorkplaceThreatsSelect.types";

type EmployerRecommendationTypes = {
  code: string,
  recommendationsList: Array<{threat: WorkplaceThreatsTypes, recommendation: string}>
}

export const employerRecommendations: EmployerRecommendationTypes[] = [
  {
    code: "B130",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.TIME_PRESSURE,
        recommendation: `W sytuacji obciążenia psychicznego związanego z pracą pod presją czasu u pracowników w starszym wieku może pojawić się obniżenie energii i napędu. Może to wynikać zarówno z przyczyn obiektywnych, np. z powodu trudnych doświadczeń rodzinnych pracownika. Może też być wynikiem towarzyszących chorób. Pracodawca powinien rozważyć możliwość modyfikacji warunków pracy w kierunku zmniejszenia presji czasu. Jest bardzo ważne, aby przy akceptacji pracownika wspierać rodzaje aktywności społecznej (również pozazawodowej). Dzięki tym działaniom zwiększamy samoocenę i przekazujemy wsparcie społeczne. Jeżeli symptomy obniżenia aktywności i kontaktów społecznych utrzymują się dłuższy czas (ponad miesiąc) najlepszym rozwiązaniem będzie kontakt z lekarzem lub psychologiem. Osoby z obniżeniem aktywności ogólnej mogą w większym stopniu odczuwać obciążenie psychiczne związane z presją czasu w pracy. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf</a> <a href="https://m.ciop.pl/CIOPPortalWAR/file/93249/Kontrakt-psychologiczny.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/93249/Kontrakt-psychologiczny.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.DECISION_MAKING,
        recommendation: `W sytuacji obciążenia psychicznego związanego z koniecznością podejmowania decyzji u pracowników w starszym wieku może pojawić się obniżenie energii i napędu. Może to wynikać zarówno z przyczyn obiektywnych, np. z powodu trudnych doświadczeń rodzinnych pracownika. Może też być wynikiem towarzyszących chorób.  Pracodawca powinien rozważyć możliwość modyfikacji warunków pracy w kierunku zmniejszenia obciążenia koniecznością podejmowania decyzji lub zapewnieniem wsparcia decyzyjnego ze strony kolegów i przełożonych. Jest bardzo ważne, aby przy akceptacji pracownika wspierać rodzaje aktywności społecznej (również pozazawodowej). Dzięki tym działaniom zwiększamy samoocenę i przekazujemy wsparcie społeczne. Jeżeli symptomy obniżenia aktywności i kontaktów społecznych utrzymują się dłuższy czas (ponad miesiąc) najlepszym rozwiązaniem będzie kontakt ze specjalistą: lekarzem lub psychologiem. Osoby z obniżeniem aktywności ogólnej mogą w większym stopniu odczuwać obciążenie psychiczne związane z podejmowaniem decyzji. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/83912/Stereotypy-50-plus-Z-Mockallo-I-P-17.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/83912/Stereotypy-50-plus-Z-Mockallo-I-P-17.pdf</a> <a href="https://m.ciop.pl/CIOPPortalWAR/file/93249/Kontrakt-psychologiczny.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/93249/Kontrakt-psychologiczny.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.CONTACT_WITH_CLIENT,
        recommendation: `W sytuacji obciążenia psychicznego związanego z kontaktem z klientem. Może to wynikać zarówno z przyczyn obiektywnych, np. z powodu trudnych doświadczeń rodzinnych pracownika. Może też być wynikiem towarzyszących chorób. Jest bardzo ważne, aby przy akceptacji pracownika wspierać rodzaje aktywności społecznej (również pozazawodowej). Dzięki tym działaniom zwiększamy samoocenę i przekazujemy wsparcie społeczne. Jeżeli symptomy obniżenia aktywności i kontaktów społecznych utrzymują się dłuższy czas (ponad miesiąc) najlepszym rozwiązaniem będzie kontakt ze specjalistą: lekarzem lub psychologiem. Osoby z obniżeniem aktywności ogólnej mogą w większym stopniu odczuwać obciążenie psychiczne związane z kontaktem z klientem. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Za%C5%82%C4%85cznik_1_Broszura_format_A4.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Za%C5%82%C4%85cznik_1_Broszura_format_A4.pdf</a> <a href="https://m.ciop.pl/CIOPPortalWAR/file/83912/Stereotypy-50-plus-Z-Mockallo-I-P-17.pdf https://m.ciop.pl/CIOPPortalWAR/file/93249/Kontrakt-psychologiczny.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/83912/Stereotypy-50-plus-Z-Mockallo-I-P-17.pdf https://m.ciop.pl/CIOPPortalWAR/file/93249/Kontrakt-psychologiczny.pdf</a>`
      }
    ]
  },
  {
    code: "B140",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.TIME_PRESSURE,
        recommendation: `W sytuacji obciążenia psychicznego związanego z presją czasu u pracowników w starszym wieku mogą pojawić się trudności ze sprawnością uwagi (koncentracji, podzielności lub przerzutności uwagi). Problemy te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających aktywności percepcyjnej i manualnej angażujących uwagę czy stan sprawności uwagi pozwala na bezpieczne wykonywanie zadań. Osoby z obniżeniem sprawności uwagi mogą w większym stopniu odczuwać obciążenie psychiczne związane z presją czasu w pracy. W przypadku trudności stwarzających ryzyko dla bezpiecznego funkcjonowania w pracy wskazane jest aby pracodawca rozważył możliwość modyfikacji warunków pracy w kierunku zmniejszenia presji czasu. W niektórych przypadkach niezbędny może być kontakt z lekarzem neurologiem lub psychologiem. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.CONTACT_WITH_CLIENT,
        recommendation: `W sytuacji obciążenia psychicznego związanego z kontaktem z klientem u pracowników w starszym wieku mogą pojawić się trudności ze sprawnością uwagi (koncentracji, podzielności lub przerzutności uwagi).Problemy te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających aktywności percepcyjnej i manualnej angażujących uwagę czy stan sprawności uwagi pozwala na bezpieczne wykonywanie zadań. Osoby z obniżeniem sprawności uwagi mogą w większym stopniu odczuwać obciążenie psychiczne związane z kontaktem z klientem. W przypadku trudności stwarzających ryzyko dla bezpiecznego funkcjonowania w pracy wskazane jest aby pracodawca rozważył możliwość modyfikacji warunków pracy w kierunku zmniejszenia obciążenia emocjonalnego związanego z kontaktem z klientem. W niektórych przypadkach niezbędny może być kontakt z lekarzem neurologiem lub psychologiem. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Załącznik_1_Broszura_format_A4.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Załącznik_1_Broszura_format_A4.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.MOVING_PARTS_OF_MACHINES,
        recommendation: `W sytuacji obsługi maszyn pracownicy w starszym wieku mogą doświadczać obniżenia sprawności uwagi (koncentracji, podzielności lub przerzutności uwagi). Problemy te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających aktywności percepcyjnej i manualnej angażujących uwagę czy stan sprawności uwagi pozwala na bezpieczne wykonywanie zadań. Osoby z obniżeniem sprawności uwagi mogą w większym stopniu doświadczać zagrożeń związanych z pracą przy ruchomych elementach maszyn. W przypadku trudności stwarzających ryzyko dla bezpiecznego funkcjonowania w pracy wskazane jest aby pracodawca rozważył możliwość modyfikacji warunków pracy w kierunku zmniejszenia presji czasu. W niektórych przypadkach niezbędny może być kontakt z lekarzem neurologiem lub psychologiem.. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf</a>`
      }
    ]
  },
  {
    code: "B144",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.TIME_PRESSURE,
        recommendation: "W sytuacji obciążenia psychicznego związanego z presją czasu u pracowników w starszym wieku mogą pojawić się trudności z pamięcią.  Sprawność pamięci jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznym treningiem funkcji poznawczych m.in. w aktywności zawodowej. Zaburzenia mogą pojawiać się również w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. W przypadku trudności w codziennym funkcjonowaniu z powodu zaburzeń pamięci najleprzym rozwiązaniem jest konsultacja z lekarzem neurologiem lub psychologiem."
      },
      {
        threat: WorkplaceThreatsTypes.DECISION_MAKING,
        recommendation: "W sytuacji obciążenia psychicznego związanego z koniecznością podejmowania decyzji u pracowników w starszym wieku mogą pojawić się problemy z pamięcią.  Sprawność pamięci jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznym treningiem funkcji poznawczych m.in. w aktywności zawodowej. Zaburzenia mogą pojawiać się również w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. W przypadku trudności w codziennym funkcjonowaniu z powodu zaburzeń pamięci najleprzym rozwiązaniem jest konsultacja z lekarzem neurologiem lub psychologiem."
      },
      {
        threat: WorkplaceThreatsTypes.CONTACT_WITH_CLIENT,
        recommendation: `W sytuacji obciążenia psychicznego związanego z kontaktem z klientem u pracowników w starszym wieku mogą pojawić się trudności z pamięcią.  Sprawność pamięci jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznym treningiem funkcji poznawczych m.in. w aktywności zawodowej. Zaburzenia mogą pojawiać się również w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. W przypadku trudności w codziennym funkcjonowaniu z powodu zaburzeń pamięci najleprzym rozwiązaniem jest konsultacja z lekarzem neurologiem lub psychologiem. Więcej informacji znajduje się w: <a href='https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Załącznik_1_Broszura_format_A4.pdf' target='_blank' rel='noreferrer noopener'>https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Załącznik_1_Broszura_format_A4.pdf</a>`
      }
    ]
  },
  {
    code: "B152",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.TIME_PRESSURE,
        recommendation: "W sytuacji obciążenia psychicznego związanego z presją czasu u pracowników w starszym wieku mogą pojawić się problemy z odczuwaniem i regulacją emocji (np. złości i gniewu, lęku lub chwiejności emocjonalnej, ale także spadku motywacji do pracy, a nawet pojawienie się zachowań skierowanych przeciwko pracodawcy/organizacji). Mogą one wynikać z doświadczania silnego stresu wynikającego z presji czasu. Można przypuszczać, że osoba mająca taki wynik może mieć trudności w wykonywaniu zadań pod presją czasu, szczególnie gdy występują dodatkowe sytuacje stresujące. Zadaniem pracodawcy jest sprawdzenie, czy modyfikacja określonych warunków pracy (np. zbyt dużej  ilości pracy, nieregularności obciążeń, itp.) może wpłynąć poprawę funkcji emocjonalnych pracownika."
      },
      {
        threat: WorkplaceThreatsTypes.DECISION_MAKING,
        recommendation: `W sytuacji obciążenia psychicznego związanego z koniecznością podejmowania decyzji u pracowników w starszym wieku mogą pojawić się problemy z odczuwaniem i regulacją emocji (np. złości i gniewu, lęku lub chwiejności emocjonalnej, ale także spadku motywacji do pracy, a nawet pojawienie się zachowań skierowanych przeciwko pracodawcy/organizacji). Mogą one wynikać z doświadczania silnego stresu. Można przypuszczać, że osoba mająca taki wynik  może mieć trudności w podejmowaniu racjonalnych decyzji, szczególnie gdy występują dodatkowe sytuacje stresujące. Zadaniem pracodawcy jest sprawdzenie, czy modyfikacja określonych warunków pracy (np. zbyt dużej  ilości pracy, nieregularności obciążeń, itp.) może wpłynąć poprawę funkcji emocjonalnych pracownikaWięcej informacji znajduje się w: <a href='https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Załącznik_1_Broszura_format_A4.pdf' target='_blank' rel='noreferrer noopener'>https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Załącznik_1_Broszura_format_A4.pdf</a> <a href='https://www.zus.pl/documents/10182/167752/2018Prewencja_i_Rehabilitacja_nr_1_2018_51/6100f41b-8bed-19bd-32ff-0aba5416ebab' target='_blank' rel='noreferrer noopener'>https://www.zus.pl/documents/10182/167752/2018Prewencja_i_Rehabilitacja_nr_1_2018_51/6100f41b-8bed-19bd-32ff-0aba5416ebab</a>`
      },
      {
        threat: WorkplaceThreatsTypes.CONTACT_WITH_CLIENT,
        recommendation: `W sytuacji obciążenia psychicznego związanego z kontaktem z klientem u pracowników w starszym wieku mogą pojawić się problemy z odczuwaniem i regulacją emocji (np. złości i gniewu, lęku lub chwiejności emocjonalnej, ale także spadku motywacji do pracy, a nawet pojawienie się zachowań skierowanych przeciwko pracodawcy/organizacji). Mogą one wynikać z doświadczania silnego stresu. Można przypuszczać, że osoba mająca taki wynik  może mieć trudności w w sytuacji pracy z klientem, szczególnie gdy mogą występować dodatkowe sytuacje roszczeniowe lub potencjalnie konfliktowe. Zadaniem pracodawcy jest sprawdzenie, czy modyfikacja określonych warunków pracy (np. zbyt dużej  ilości pracy, trudności w pracy z klientem, itp.) może wpłynąć poprawę funkcji emocjonalnych pracownika. Więcej informacji znajduje się w: <a href='https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Załącznik_1_Broszura_format_A4.pdf' target='_blank' rel='noreferrer noopener'>https://m.ciop.pl/CIOPPortalWAR/file/84459/Stres_w_pracy_Załącznik_1_Broszura_format_A4.pdf</a> <a href="https://www.zus.pl/documents/10182/167752/2018Prewencja_i_Rehabilitacja_nr_1_2018_51/6100f41b-8bed-19bd-32ff-0aba5416ebab" target="_blank" rel="noreferrer noopener">https://www.zus.pl/documents/10182/167752/2018Prewencja_i_Rehabilitacja_nr_1_2018_51/6100f41b-8bed-19bd-32ff-0aba5416ebab</a>`
      }
    ]
  },
  {
    code: "B240",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.LEVEL_DIFFERENCE,
        recommendation: "Przebyte ostre i nawracające epizody zawrotów głowy powinny być przeciwwskazaniem do wykonywania pracy na wysokości. W trosce o bezpieczeńśtwo i zdrowie pracownika należy wykonywać te czynności biorąc pod uwagę kondycję pracownika."
      },
      {
        threat: WorkplaceThreatsTypes.NOISE,
        recommendation: "Hałas występujący na stanowisku pracy może negatywnie oddziaływać na pracownika, szczególnie w przypadku występujących problemów ze słuchem. Nieodpowiednia akustyka w pomieszczeniu utrudnia osobom słabosłyszącym komunikację. Regularne badania (w tym badania okresowe) umożliwią zapewnienie pracownikowi odpowiedniego wsparcia."
      },
      {
        threat: WorkplaceThreatsTypes.MOVING_PARTS_OF_MACHINES,
        recommendation: "W przypadku występujących u pracownika problemów ze słuchem, należy dokładnie przeanalizować zagrożenie wypadkowe związane z pracą przy ruchomych elementach maszyn występujące. Nalęy podjąć działania minimalizujące ryzyko w tym zakresie. Funkcje załączania maszyn, urządzeń i ich podzespołów powinny być sygnalizowane sygnałem świetlnym (np. przez podświetlenie przycisku lub zapalenie się kontrolki sygnalizacyjnej) oraz odpowiednio wzmocnionym sygnałem dźwiękowym wspomaganym sygnałem wibracyjnym elementu sterowniczego."
      }
    ]
  },
  {
    code: "B280",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.MOVING_CARGO,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      },
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      },
      {
        threat: WorkplaceThreatsTypes.MICROCLIMATE,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      },
      {
        threat: WorkplaceThreatsTypes.LEVEL_DIFFERENCE,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      },
      {
        threat: WorkplaceThreatsTypes.NOISE,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      },
      {
        threat: WorkplaceThreatsTypes.TIME_PRESSURE,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      },
      {
        threat: WorkplaceThreatsTypes.DECISION_MAKING,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      },
      {
        threat: WorkplaceThreatsTypes.CONTACT_WITH_CLIENT,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      },
      {
        threat: WorkplaceThreatsTypes.MOVING_PARTS_OF_MACHINES,
        recommendation: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, róznorodność czynności, dostosowanie zadań do jego możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy."
      }
    ]
  },
  {
    code: "B420",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.MOVING_CARGO,
        recommendation: "Wysiłek fizyczny można potraktować zarówno jako czynnik prewencyjny, jak i jako czynnik ryzyka w odniesieniu do chorób układu krążenia, w tym nadciśnienia. Konieczność podejmowania wysiłku w ramach obowiązków zawodowych może stać się przeciwwskazaniem do wykonywania zawodu w poszczególnych grupach osób ze schorzeniami kardiologicznymi. Aby wysiłek wpływał pozytywnie na obniżenie ciśnienia tętniczego, musi mieć charakter umiarkowany i dynamiczny, związany z ruchem. Wykonywanie przez wiele lat ciężkiej pracy fizycznej zwiększa ryzyko rozwoju chorób układu krążenia. W związku z tym należy (w przypadku stanowisk pracy osób starszych) zapewnić organizację pracy umożliwiającą wykonywanie różnorodnych czynności, a sumaryczny wydatek energetyczny powinien być na poziomie niskim lub średnim."
      },
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: "Przeciążenie układu ruchu związane z długotrwałym utrzymywaniem pozycji ciała, szczególnie w pozycjiach wymuszonych może mieć negatywny wpływ na pracownika w wieku przedemerytalnycm, szczeólnie w przypadku występowania problemów z ciśnieniem tętniczym. Umiarkowany wysiłek fizyczny może obniżyć ciśnienie tętnicze zarówno u osób z nadciśnieniem, jak i bez nadciśnienia. Ćwiczenia fizyczne wpływają pozytywnie na elastyczność ścian naczyń, dzięki czemu nie tylko zapobiegają nadciśnieniu tętniczemu, ale pozwalają także obniżyć jego podwyższone wartości. Ponadto uprawianie sportu pomaga zmniejszyć masę ciała i utrzymać ją na prawidłowym poziomie, co również przyczynia się do spadku ciśnienia i w początkowych fazach choroby może zastąpić leczenie farmakologiczne. Aby wysiłek wpływał pozytywnie na obniżenie ciśnienia tętniczego, musi mieć charakter umiarkowany i dynamiczny, związany z ruchem, gdyż wysiłki o charakterze izometrycznym (napinanie mięśni, z brakiem lub niewielką zmianą ich długości), mogą podwyższać ciśnienie tętnicze krwi."
      },
      {
        threat: WorkplaceThreatsTypes.MICROCLIMATE,
        recommendation: "Wykonywanie pracy w mikroklimacie zimnym, gorącym lub zminnym może mieć negatywny wpływ na pracownika w wieku przedemerytalnym i emerytalnym, szczególnie w przypadku występowania u niego problemów z ciśnieniem tętniczym. Skurcz naczyń krwionośnych pod wpływem zimna powoduje zwiększenie oporu naczyniowego na obwodzie, czego następstwem jest podwyższenie ciśnienia skurczowego krwi i obciążenie serca pracą zwiększającą zapotrzebowanie na tlen. Z drugiej strony, praca w warunkach narażenia na niskie temperatury otoczenia może spowodować u osób z już istniejącymi chorobami układu krążenia (choroba wieńcowa serca, nadciśnienie tętnicze) pogorszenie parametrów hemodynamicznych i zaostrzenie dolegliwości. W związku z tym, ważne jest zwrócenie uwagi w trakcie badań wstępnych i okresowych osób pracujących na stanowiskach, gdzie występuje narażenie na niskie temperatury otoczenia, na stan układu krążenia"
      },
    ]
  },
  {
    code: "B455",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.MOVING_CARGO,
        recommendation: `Obciążenie fizyczne związane z wykonywaniem czynności pracy może przewyższać tolerancję wysiłku pracownika. Należy wziąć pod uwagę weryfikację organizacji pracy, w szczególności ograniczenie czynności wymagających dużego wydatku energetycznego. Wraz z wiekiem obniża się zdolność wysiłkowa człowieka. Już od ok. 25 r.ż. obserwuje się regularny spadek wydolności fizycznej, a w 50 r.ż. wydolność fizyczna zmniejsza się do ok. 70% swej maksymalnej wartości. W wieku 60 lat jest to 60–65% „szczytowych” wielkości osiąganych w wieku ok. 30 lat. Natomiast w 70 r.ż. obserwuje się spadek wydolności do ok. 55%. Dla grupy pracowników 60+ wykonana praca jest odczuwalna jako praca bardziej wyczerpująca. Na stanowisku pracy wartość wydatku energetycznego w wielu przypadkach nie jest mierzona tylko szacowana na podstawie danych tabelarycznych zawartych w normie, które były opracowane dla zdrowego i wydolnego fizycznie 30-latka. W obecnie funkcjonujących przepisach wartości te nie są korygowane ze względu na wiek pracownika i tym samym nie uwzględniają spadku jego wydolności fizycznej. Badania CIOP-PIB potwierdziły, iż wartości dopuszczalne obciążenia cieplnego oraz obciążenia wysiłkiem fizycznym dla pracowników starszych powinny być obniżone. Więcej informacjiznajduje sie w: <a href="https://www.ciop.pl/CIOPPortalWAR/file/89415/Ulotka-2-G-08-2019-M-Mlynarczyk.pdf" target="_blank" rel="noreferrer noopener">https://www.ciop.pl/CIOPPortalWAR/file/89415/Ulotka-2-G-08-2019-M-Mlynarczyk.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: "Obciążenie fizyczne związane z wykonywaniem czynności pracy i utrzymywaniem wymuszonych pozycji ciała przez dłuższy czas,  może przewyższać tolerancję wysiłku pracownika. Należy wziąć pod uwagę weryfikację organizacji pracy, w szczególności ograniczenie czynności wymagających dłudotrwałego utrzymywania pozycji nieruchomej, pochylonej, zwłaszcza podczas wykonywania pracy w pozycji stojącej."
      },
      {
        threat: WorkplaceThreatsTypes.MICROCLIMATE,
        recommendation: `Wykonywanie pracy w srodowisku goracym/zimnym może powodować dodatkowe, poza wysiłkem fizycznym obciazenie organizmu pracownika. Należy wziąć pod uwagę weryfikację organizacji pracy, w szczególności ograniczenie czasu pracy w wysokiej/niskiej temperaturze otoczenia oraz zapewnienie odpowiedniej ilości płynów w przypadku środowiska goracego oraz odzieży w przypadku srodowiska zimnego. Wraz z wiekiem zmniejsza się zdolność do znoszenia upału, nawet w większym stopniu niż zdolność do znoszenia ciężkiej pracy fizycznej. Podczas wykonywania pracy w środowisku gorącym może dojść do sytuacji, gdy oddawanie ciepła nie będzie nadążało za jego wytwarzaniem. Świadczy to o załamaniu układu termoregulacji organizmu, co z kolei może prowadzić do udaru cieplnego. Więcej informacjiznajduje sie w: <a href="https://www.ciop.pl/CIOPPortalWAR/file/89415/Ulotka-2-G-08-2019-M-Mlynarczyk.pdf" target="_blank" rel="noreferrer noopener">https://www.ciop.pl/CIOPPortalWAR/file/89415/Ulotka-2-G-08-2019-M-Mlynarczyk.pdf</a> Uruchomienie mechanizmów termoregulacji, umożliwiających człowiekowi przebywanie w wysokiej temperaturze otoczenia, odbywa się dodatkowym kosztem „fizjologicznym”, czyli obciążeniem układu krążenia. Rozszerzenie naczyń skóry w gorącym otoczeniu może tak znacznie zmniejszyć opory naczyniowe, że obniża się ciśnienie tętnicze krwi. Może to spowodować omdlenie. Wysiłek fizyczny zwiększa obciążenie serca, czego wyrazem jest przyspieszenie akcji serca. Jednoczesne zmniejszenie napięcia naczyń kończyn dolnych powoduje zmniejszenie dopływu krwi do serca. To z kolei może powodować niedokrwienie i zapaść. Ekspozycja na wysoką temperaturę zmniejsza przepływ krwi przez nerki. Powoduje to konieczność powstrzymania się od pracy w takim narażeniu przez osoby z chorobami układu krążenia.`
      }
    ]
  },
  {
    code: "B710",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.MOVING_CARGO,
        recommendation: "Ograniczona ruchomość w stawach oraz w kręgosłupie wystepująca wśród pracowników może ograniczać wykonywanie niektórych czynności pracy. Konsekwencją pracy o dużym wydatku energetycznym będzie znacznie przyspieszona degeneracja układu mięśniowo-szkieletowego. Jeśli brak ruchomości w stawach jest wynikiem przykurczy tkanek miękkich (braku mobilności) należy zadbać o odpowiednio dobraną aktywność fizyczną pracowników, która pozwoli im zadbać o układ mięśniowo-szkieletowy i utrzymać zdolność pracy. Rekomendowane jest prowadzenie cyklicznych warsztatów, na których pracownicy mogliby nauczyć się jak łagodzić skutki nadmiernego przeciążenia układu mieśniowo-szkieletowego w wyniku pracy fizycznej. Jeśli ochraniczenie ruchomości w stawach jest wynikiem uszkodzenia na poziomie struktury a pracownik przeszedł rehabilitację i mimo niej problem wystęuje, to nie powinien wykonywać prac ciężkich i bardzo ciężkich. Rekomenduje się stosowanie urządzeń transportowych eliminujących pracę ręczną (np. wózków, podnośników, transporterów). Zapoznanie pracowników z normami i technikami dźwigania ciężkich przedmiotów oraz przestrzeganie ich stosowania. Stosowanie przenoszenia zespołowego przez dwóch lub trzech pracowników. Zastosowanie rotacji pracowników na stanowiskach pracy związanych z przenoszeniem i podnoszeniem ciężkich przedmiotów."
      },
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: "Ograniczona ruchomość w stawach oraz w kręgosłupie wystepująca wśród pracowników może ograniczać przyjmowanie pozycji wymuszonych. Rekomendowane jest prowadzenie cyklicznych warsztatów, na których pracownicy mogliby  nauczyć się jak łagodzić skutki nadmiernego przeciążenia układu mieśniowo-szkieletowego w wyniku pracy w wymuszonych pozycjach ciała. Osoby z ograniczoną funkcją ruchomości stawów nie powinny wykonywać czynności roboczych, które zmuszją ich do przyjmowania długotrwałych lub częstych pozycji wymusoznych. Rekomenduje się zapewnienie pracownikom możliwości zmiany pozycji przy pracy, zapewnienie łatwego dostępu do wyposażenia na stanowisku pracy, dostosowanie struktury przestrzennej stanowiska pracy do wysokości ciała pracownika i zasięgu jego kończyn, stosowanie przerw w pracy i rotacji pracowników."
      },
      {
        threat: WorkplaceThreatsTypes.LEVEL_DIFFERENCE,
        recommendation: "Ograniczona ruchomość w stawach oraz w kręgosłupie wystepująca wśród pracowników może ograniczać przyjmowanie pozycji ciała, zdolności funkcjonalne układu mięśniowo-szkieletowego, co może przyczyniać się do podniesienia poziomu ryzyka wypadku przy pracy na wysokości. Rekomendowane jest prowadzenie cyklicznych warsztatów, na których pracownicy mogliby nauczyć się zwiększyć ruchomość w stawach i przywrócić naturalne wzorce ruchu, umożliwiające swobodne wykonwyanie czynności roboczych i zachowanie zdolności do pracy."
      }
    ]
  },
  {
    code: "B730",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.MOVING_CARGO,
        recommendation: `Na stanowiskach na których pracują osoby starsze należy ograniczyć wywierane siły o 14% dla populacji mężczyzn i 24% dla populacji kobiet. <a href="https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf" target="_blank" rel="noopener noreferrer">https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: `Na stanowiskach na których pracują osoby starsze należy ograniczyć wywierane siły o 14% dla populacji mężczyzn i 24% dla populacji kobiet. <a href="https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf" target="_blank" rel="noopener noreferrer">https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf</a>`
      },
    ]
  },
  {
    code: "D110",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.LEVEL_DIFFERENCE,
        recommendation: "Wykonywanie pracy na wysokości przez osoby z dysfunkcja narzadu wzroku niesie za soba ryzyko wypadków przy pracy dlatego noszenie szkieł korekcyjnych stanowi przeciwwskazanie do zatrudnienia przy pracy na wysokościach. Przepis ten dotyczy jednak wysokości powyżej 3 metrów. Istnieje mozliwośc podjecia pracy na wysokości przez osoby z wadami wzroku jednak powinny one zgłosić się do specjalisty. w celu doboru soczewek kontaktowych."
      },
      {
        threat: WorkplaceThreatsTypes.MOVING_PARTS_OF_MACHINES,
        recommendation: "Jednooczność lub niedowidzenie na jedno oko zaburza widzenie przestrzenne i w związku z tym jest przeciwskazaniem do wykonywania prac związanych z obsługa maszy z ruchomymi elementami."
      }
    ]
  },
  {
    code: "D160",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.TIME_PRESSURE,
        recommendation: `W sytuacji obciążenia psychicznego związanego z presją czasu u pracowników w starszym wieku może nastąpić obniżenie sprawności uwagi (koncentracji, podzielności lub przerzutności uwagi). Problemy te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających aktywności percepcyjnej i manualnej angażujących uwagę czy stan sprawności uwagi pozwala na bezpieczne wykonywanie zadań. Osoby z obniżeniem sprawności uwagi mogą w większym stopniu odczuwać obciążenie psychiczne związane z presją czasu w pracy. W przypadku trudności utrudniających bezpieczne funkcjonowanie w codziennym życiu i w pracy  najlepszym rozwiązaniem będzie kontakt z lekarzem neurologiem lub psychologiem. Pracodawca powinien sprawdzić, czy modyfikacja określonych warunków pracy (np. znaczącego hałasu, przeciążenia pracą) nie poprawi pracownikowi możliwości skupiania uwagi. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf</a> ale także spadku motywacji do pracy, a nawet pojawienie się zachowań skierowanych przeciwko pracodawcy/organizacji )`
      },
      {
        threat: WorkplaceThreatsTypes.DECISION_MAKING,
        recommendation: `W sytuacji obciążenia psychicznego związanego z koniecznością podejmowania decyzji u pracowników w starszym wieku może pojawić się obniżenie sprawności uwagi (koncentracji, podzielności lub przerzutności uwagi). Problemy te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających aktywności percepcyjnej i manualnej angażujących uwagę czy stan sprawności uwagi pozwala na bezpieczne wykonywanie zadań. Osoby z obniżeniem sprawności uwagi mogą w większym stopniu odczuwać obciążenie psychiczne związane z presją czasu w pracy. W przypadku trudności utrudniających bezpieczne funkcjonowanie w codziennym życiu i w pracy najlepszym rozwiązaniem będzie kontakt z lekarzem neurologiem lub psychologiem. Pracodawca powinien sprawdzić, czy modyfikacja określonych warunków pracy (np. znaczącego hałasu, konieczności wykonywania kilku czynności jednocześnie, czy braku wsparcia decyzyjnego  ze strony kolegów i przełożonych) nie poprawi pracownikowi możliwości skupiania uwagi. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf" target="_blank" rel="noreferrer nopener">https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf</a> ale także spadku motywacji do pracy, a nawet pojawienie się zachowań skierowanych przeciwko pracodawcy/organizacji )`
      },
      {
        threat: WorkplaceThreatsTypes.CONTACT_WITH_CLIENT,
        recommendation: `W sytuacji obciążenia psychicznego związanego z kontaktem z klientem u pracowników w starszym wieku może pojawić się obniżenie sprawności uwagi (koncentracji, podzielności lub przerzutności uwagi). Problemy te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających aktywności percepcyjnej i manualnej angażujących uwagę czy stan sprawności uwagi pozwala na bezpieczne wykonywanie zadań. Osoby z obniżeniem sprawności uwagi mogą w większym stopniu odczuwać obciążenie psychiczne związane z presją czasu w pracy. W przypadku trudności utrudniających bezpieczne funkcjonowanie w codziennym życiu i w pracy najlepszym rozwiązaniem będzie kontakt z lekarzem neurologiem lub psychologiem. Pracodawca powinien sprawdzić, czy modyfikacja określonych warunków pracy (np. znaczącego hałasu, przeciążenia emocjonalnego kontaktem z klientem konieczności wykonywania kilku czynności jednocześnie) nie poprawi pracownikowi możliwości skupiania uwagi. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf" rel="noreferrer noopener" target="_blank">https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf</a>`
      },
    ]
  },
  {
    code: "D175",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.TIME_PRESSURE,
        recommendation: `W sytuacji obciążenia psychicznego związanego z presją czasu u pracowników w starszym wieku mogą pojawić się trudności w rozwiązywaniu problemów. Trudności te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających rozwiązywania problemów czy trudności te  pozwalają na bezpieczne wykonywanie zadań. Osoby z tymi trudnościami mogą w większym stopniu odczuwać obciążenie psychiczne związane z presją czasu w pracy. W przypadku problemów  utrudniających bezpieczne funkcjonowanie w pracy jednym z rozwiązań jest kontakt z lekarzem neurologiem lub psychologiem. Pracodawca powinien sprawdzić, czy modyfikacja określonych warunków pracy (np. zbyt dużej ilości pracy, nierównomiernie rozłożonych zadań zawodowych) nie poprawi pracownikowi możliwości skupiania uwagi. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/93243/Poradnik-presja-czasu.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.DECISION_MAKING,
        recommendation: `W sytuacji obciążenia psychicznego związanego z koniecznością podejmowania decyzji u pracowników w starszym wieku mogą pojawić się trudności w rozwiązywaniu problemów. Trudności te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających rozwiązywania problemów czy trudności te pozwalają na bezpieczne wykonywanie zadań. Osoby z tymi trudnościami mogą w większym stopniu odczuwać obciążenie psychiczne związane z koniecznością podejmowania decyzji. W przypadku problemów utrudniających bezpieczne funkcjonowanie w pracy jednym z rozwiązań jest kontakt z psychologiem/coachem. Pracodawca powinien sprawdzić, czy modyfikacja określonych warunków pracy (np. zbyt trudnych zadań, konfliktu roli, czy braku wsparcia ze strony kolegów i przełożonych) nie poprawi pracownikowi umiejętności rozwiązywania problemów. Więcej informacji znajduje się w: <a href="https://m.ciop.pl/CIOPPortalWAR/file/93249/Kontrakt-psychologiczny.pdf" target="_blank" rel="noreferrer noopener">https://m.ciop.pl/CIOPPortalWAR/file/93249/Kontrakt-psychologiczny.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.CONTACT_WITH_CLIENT,
        recommendation: `W sytuacji obciążenia psychicznego związanego z kontaktem z klientem u pracowników w starszym wieku mogą pojawić się trudności w rozwiązywaniu problemów. Trudności te mogą mieć różne przyczyny i konsekwencje. Warto ocenić przy zadaniach wymagających rozwiązywania problemów czy trudności te pozwalają na bezpieczne wykonywanie zadań. Osoby z tymi trudnościami mogą w większym stopniu odczuwać obciążenie psychiczne związane z koniecznością pracy z klientem. W przypadku problemów utrudniających wydaje funkcjonowanie w pracy jednym z rozwiązań jest kontakt z psychologiem/coachem. Pracodawca powinien sprawdzić, czy modyfikacja określonych warunków pracy (np. zbyt obciążających lub zbyt długo trwających kontaktów emocjonalnych z klientami, czy braku wsparcia ze strony kolegów i przełożonych) nie poprawi pracownikowi umiejętności pracy z klientem.`
      },
    ]
  },
  {
    code: "D415",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: "Na stanowisku pracy zidentyfikowano przyjmowane przez pracownika pozycji niewygodnych i wymuszonych, co może stanowić dla niego nadmierne obciążenie. Zaleca się, aby dążyć do utrzymywania pozycji naturalnych. Istotnym elementem jest wysokość blatu roboczego, która determinuje ułożenie kończyn górnych i tułowia. Optymalne położenie blatu roboczego określane jest w stosunku do wysokości łokciowej pracownika. Obszar pracy oraz wymiary stanowiska roboczego są czynnikami determinującymi pozycję przyjmowaną przez pracownika. Określone przestrzenne ułożenie części ciała składa się na całkowite chwilowe obciążenie, dlatego istotnym aspektem jest takie zaprojektowanie stanowiska pracy, aby obciążenie wynikające z przyjmowanej pozycji było jak najmniejsze."
      },
      {
        threat: WorkplaceThreatsTypes.LEVEL_DIFFERENCE,
        recommendation: "Na stanowisku pracy zidentyfikowano przyjmowane przez pracownika pracy na wysokości / podwyższeniu, co może stanowić dla niego nadmierne obciążenie. W takich przypadkach szczególne znaczenie dla bezpieczeństwa pracy ma unikanie pozycji niewygodnych i wymuszonych oraz przyjmowanie pozycji naturalnych, najmniej obciążających układ mięśniowo-szkieletowy. W związku z tym rekomenduje się prowadzenie cyklicznych szkoleń oraz zapewnienie organizacji pracy odpowiedniej do możliwości pracownika."
      },
    ]
  },
  {
    code: "D430",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.MOVING_CARGO,
        recommendation: `Możliwości fizyczne pracownika mogą być niewystarczające do wykonywania czynności związanych z dużym wydatkiem energetycznym. Podczas wykonywania ręcznych prac transportowych należy stosować się do kilku głównych zasad:<br/>
                          1. stosować sprzęt pomocniczy w celu zmniejszenia obciążenia układu mięśniowo-szkieletowego, a w szczególności kręgosłupa<br/>
                          2. unikać dużych skrętów i niepotrzebnego pochylania tułowia<br/>
                          3. dążyć do tego, aby pozycja ciała była możliwie najbardziej zbliżona do naturalnej<br/>
                          4. najkorzystniejszą wysokością, z której podnoszone są przedmioty, jest wysokość blatu stołu, wysokość ta powinna być dopasowana indywidualnie<br/>
                          5. pole podstawy układu człowiek - przedmiot powinno być jak największe, ale nie powinno zwiększać obciążenia kończyn dolnych<br/>
                          6. stosować odpowiednie sposoby podczas podnoszenia przedmiotów nietypowych, o dużych rozmiarach czy dużej masie<br/>
                          7. przedmioty przenoszone powinny mieć uchwyty<br/>
                          8. przedmioty o dużych rozmiarach i dużej masie powinny być przenoszone zespołowo z zachowaniem wszystkich ww. środków bezpieczeństwa i zaleceń dotyczących sposobów podnoszenia i przenoszenia przedmiotów<br/>
                          Więcej informacji na temat doboru i zastosowania urządzeń wspomagających przenoszenie ładunków znajdziesz w poradniku: <a href="https://www.ciop.pl/CIOPPortalWAR/file/79681/Poradnik-doboru-i-zastosowania-urzadzen-wspomagajacych-przenoszenie.pdf" target="_blank" rel="noreferrer noopener">https://www.ciop.pl/CIOPPortalWAR/file/79681/Poradnik-doboru-i-zastosowania-urzadzen-wspomagajacych-przenoszenie.pdf</a>`
      }
    ]
  },
  {
    code: "D440",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: `Na stanowisku pracy zidentyfikowano przyjmowane przez pracownika pozycji niewygodnych i wymuszonych, co może stanowić dla niego nadmierne obciążenie. W przypadku wykonywania prac manipulacyjnych nalezy zwrócić uwagę, aby ręce nie były podnoszone wyżej niż na 100mm ponad poziom łokci. Natomiast podczas prac dokładnych wymagających precyzji, w których niezbędna jest szczegółowa kontrola wzrokowa, wysokość położenia płaszczyzny roboczej powinna być wyższa niż podczas pozostałych prac. Więcej informacji można znaleźć w poradniku: <a href="https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf" target="_blank" rel="noreferrer noopener">https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf</a>`
      },
    ]
  },
  {
    code: "D445",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.MOVING_CARGO,
        recommendation: `Przeciążenie układu ruchu wynikające z obciażenia pracą dynamiczną może wiązać się z używaniem rąk i ramion. Gdy wykonywane są powtarzalne ruchy rąk i ramion, praca może być wykonywana w pozycji męczącej i powodującej ból. Badania pokazują, że 61% pracowników narażonych jest na powtarzalne ruchy rąk i ramion a 43% pracowników narażonych jest na pracę w pozycji męczącej i powodującej ból przez co najmniej jedną czwartą czasu pracy. Czynniki takie jak tempo pracy, precyzja wykonywanych zadań czy wielkość obciążenie bezpośrednio przekładają się na kondycje układu mięśniowo-szkieletowego kończyn górnych. W związku z tym istotne jest, aby znaleźć sposoby zmniejszenia obciążenia układu ruchu pojawiającego się w wyniku wykonywania powtarzalnych prac fizycznych angażujących kończyny górne. Zalecane w takim przypadku jest: ergonomiczne dostosowywanie stanowisk pracy do rodzaju i charakteru wykonywanych czynności, odpowiednie dobranie narzędzi dodatkowych, organizacja czasu i tempa pracy dostosowana do możliwości pracownika, rotacja na stanowiskach pracy oraz odpowiednio rozplanowane przerwy na odpoczynek. Więcej informacji znajdziesz w poradniku: <a href="https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf" target="_blank" rel="noreferrer noopener">https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf</a>`
      },
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: `Konieczność wykonywania powtarzalnych ruchów rąk i ramion oraz praca w męczącej i powodującej ból pozycji są dość powszechne, odpowiednio 61% and 43% pracowników jest narażonych na takie ryzyko przez co najmniej jedną czwartą czasu pracy. Czynniki takie jak tempo pracy, precyzja wykonywanych zadań czy wielkość obciążenie bezpośrednio przekładają się na kondycje układu mięśniowo-szkieletowego kończyn górnych. W związku z tym istotnym jest, aby znaleźć sposoby zmniejszenia obciążenia układu ruchu pojawiającego się w wyniku wykonywania powtarzalnych prac fizycznych angażujących kończyny górne. Zalecenia-przeciwdziałanie: <br/>
                            1. Ergonomiczne dostosowywanie stanowisk pracy do rodzaju i charakteru wykonywanych czynności<br/>
                            2. Odpowiednie dobranie narzędzi dodatkowych<br/>
                            3. Organizacja czasu i tempa pracy<br/>
                            4. Rotacja na stanowiskach pracy<br/>
                            5. Przerwy na odpoczynek<br/>
                            Więcej informacji znajdziesz w Łach zalecenia 2016 ( <a href="https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf" target="_blank" rel="noreferrer noopener">https://www.ciop.pl/CIOPPortalWAR/file/91554/Zalecenia_i_przyklady_dobrych_praktyk_2Z24.pdf</a>)`
      },
    ]
  },
  {
    code: "D450",
    recommendationsList: [
      {
        threat: WorkplaceThreatsTypes.MOVING_CARGO,
        recommendation: "Zabużony wzorzec chodu powodujący problemy z przemieszczaniem się na tym samym poziomie może stwarzać duże zagrożenie pojawienia się wypadku w czasie wykonywania pracy generującej wydatek energetyczny przekraczający dla kobiet 1100 kcal a dla mężczyzn 1500 kcal lub w przypadku wykonywania czynności przemieszczania ładunków (ręcznego czy z uzyciem urządzeń ręcznych wspomaganych elektrycznie). Osoby z dysfunkcją chodu nie powinny wykonywać prac ciężkich i bardzo ciężkich."
      },
      {
        threat: WorkplaceThreatsTypes.FORCED_BODY_POSITION,
        recommendation: "Zaburzenie wzorca chodu spowodowane jest  najczęściej uszkodzeniem układu mięśniowo-szkieletowego (kończyn dolnych, obręczy kończyn dolnej, kręgosłupa) lub/i układu nerwowego. W zależności od poziomu uszkodzenia struktur ciała obserwuje się inny poziom dysfunkcji czynności funkcjonalnych.  W W większości przypadków osoby mające zaburzony wzorzec chodu, mające problemy z przemieszczaniem się na tym samym poziomie, nie moga przyjmować pozycji wymuszonych, takich jak pozycja z pochylonym tułowiem, kuczna czy klęczna. W tym przypadku należy umożliwić pracownikowi pracę w pozycji siedzącej. Natomiast jeśli zaburzenie chodu wynika z dyfunkcji funkcji kręgosłupa pracownik najpewniej będzie miał również problem z przyjmowaniem długotrwałej pozycji siedzącej. Rekomendowane jest stanowsiko z możliwością swobodnej zmiany pozycji, w zależności od potrzeb pracownika. "
      },
      {
        threat: WorkplaceThreatsTypes.LEVEL_DIFFERENCE,
        recommendation: "Zaburzony wzorzec chodu, problemy z przemieszczaniem się na tym samym poziomie będzie podnosiło ryzyko wystapienia wypadku podczas pracy, jeśli pracownik będzie przemieszczał się na podwyższeniu (na rusztowaniu, podeście, podnośniku). dysfunkcja chodu uniemożliwia bezpieczną pracę na wysokości, w przypadku gdy pracownik musi sam wejść po rusztowaniu czy drabinie.  Należy rozważyć stosowanie urządzeń wspomagajacych pracę na wysokości, np. podnośniki koszowe, czy podesty ruchome."
      },
    ]
  },
]