import {makeStyles, createStyles, Theme} from '@material-ui/core/styles'

export const useAdvisoryActivitiesCardStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(1)
  }
}))