import {ExamResultTypes} from "../../testedPersons/examsResults/ExamsResult.types";
import {ColorLight} from "./exam.types";

export const getAnswerColor = (answer: string, isAnswerCrucial: boolean, isECode: boolean): ColorLight | undefined => {
  if(isECode) {
    if(["0", "+1", "+2", "+3", "+4"].includes(answer)) {
      return ColorLight.GREEN
    } else if(["1", "2", "3", "4"].includes(answer)) {
      return ColorLight.YELLOW
    }
  } else {
    if(["0","1"].includes(answer)
      || (answer === "2" && !isAnswerCrucial)
    ) {
      return ColorLight.GREEN
    } else if ((answer === "2" && isAnswerCrucial)
      || (answer === "3" && !isAnswerCrucial)
    ) {
      return ColorLight.YELLOW
    } else if ((answer === "3" && isAnswerCrucial)
      || (answer === "4")
    ) {
      return ColorLight.RED
    }
  }
}

export const getLabelForColor = (color: ColorLight | undefined, examResultType: ExamResultTypes) => {
  if (examResultType === ExamResultTypes.GUARDIAN_RESULT) {
    switch (color) {
      case ColorLight.GREEN:
        return "Doskonale"
      case ColorLight.YELLOW:
        return "Dobrze"
      case ColorLight.RED:
        return "Wskazane zmiany"
      default:
        return null
    }
  } else if (examResultType === ExamResultTypes.EXAMINED_PERSON_RESULTS) {
    switch (color) {
      case ColorLight.GREEN:
        return "Doskonale"
      case ColorLight.YELLOW:
        return "Dobrze"
      case ColorLight.RED:
        return "Niezbędne zmiany"
      default:
        return null
    }
  }
}