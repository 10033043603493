import {FC} from 'react'
import ExamPageCard from "../components/examComponents/examPageCard/ExamPageCard.component";
import {EXAM_QUESTIONS} from "../components/examComponents/examUtils/examQuestions.utils";
import ExamAnswer from "../components/examComponents/examAnswer/ExamAnswer.component";
import AdminPageCard from "../components/adminPageCard/AdminPageCard.component";
import {RouteComponentProps, useHistory, useParams} from "react-router";
import {ExamResultTypes} from "../components/testedPersons/examsResults/ExamsResult.types";
import {RoutePath} from "../routes/Routes.types";
import {isIdValidated} from "../utils/validateId";
import {USER_QUERY} from "../store/user/user.query";
import {useQuery} from "@apollo/client";
import {ExaminedPersonUserItemNode} from "../store/user/user.types";
import LoaderBox from "../components/common/loaderBox/LoaderBox.component";
import { Button, CircularProgress, Typography} from "@material-ui/core";
import useFileDownload from "../hooks/useFileDownload";
import getLocalISOTime from "../utils/getLocalISOTime";
import {
  getExamResultsTypeForPdf,
  getExamResultsTypeLabel
} from "../components/testedPersons/examsResults/ExamsResult.utils";
import ExamResultGeneralDescription
  from "../components/examComponents/examResultGeneralDescription/ExamResultGeneralDescription.component";
import AdvisoryActivitiesCard from "../components/advisoryActivitiesCard/AdvisoryActivitiesCard.component";
import ExamResultsTable from "../components/examComponents/examResultsTable/ExamResultsTable.component";

export interface ExamResultPageProps {
  userId: string,
  examResultType: ExamResultTypes
}

const ExamResultPage: FC<RouteComponentProps<ExamResultPageProps>> = () => {
  const {userId, examResultType} = useParams<ExamResultPageProps>()
  const {push} = useHistory()

  const {data, loading, refetch} = useQuery<{user: ExaminedPersonUserItemNode}>(USER_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      userId
    },
  })

  const examResultsTypeForPdf = examResultType && getExamResultsTypeForPdf(examResultType)
  const {download, downloading} = useFileDownload(`/api/results/${userId}/${examResultsTypeForPdf}`);
  // URL: api/results/:userId/:type

  const onGeneratePdfButtonClick = () => {
    const fileName = `${getExamResultsTypeLabel(examResultType)} - ${getLocalISOTime()} - ${userId}.pdf`
    download(fileName)
  }

  if (!userId
    || !isIdValidated(userId)
    || !Object.values(ExamResultTypes).includes(examResultType)
    || !examResultType
    || (data && !data.user.finishedExamAt)
  ) {
    push(RoutePath.ERROR_404)
  }

  return (
    <AdminPageCard
      title={examResultType === ExamResultTypes.GUARDIAN_RESULT
        ? "Karta wyników dla pracodawcy"
        : "Karta wyników dla pracownika"
      }
    >
      {loading && !data
        ? <LoaderBox/>
        : (
          <ExamPageCard fullName={data!.user.fullName}>
            <div id="pageIsReadyForPrinting"/>

            <ExamResultGeneralDescription
              userAnswers={data!.user.answersList}
              userOccupationType={data!.user.occupationType.occupationTypeId}
            />

            { examResultType === ExamResultTypes.EXAMINED_PERSON_RESULTS && (
              <ExamResultsTable userExamData={data!.user}/>
            )}

            <Typography variant={"h5"} component={"p"} className='print-page-break-before'>
              Ocena szczegółowa
            </Typography>

            {EXAM_QUESTIONS.map(question => (
              <ExamAnswer
                key={question.code}
                userAnswers={data!.user.answersList}
                userOccupationType={data!.user.occupationType.occupationTypeId}
                userThreats={data!.user.workplaceThreats}
                examResultType={examResultType}
                userId={data!.user.userId}
                refetch={refetch}
                {...question}
              />
            ))}

            <AdvisoryActivitiesCard resultType={examResultType}/>

            <div
              className={"print-hidden"}
              style={{ float: "right" }}
            >
              <Button
                onClick={onGeneratePdfButtonClick}
                disabled={downloading}
                variant="contained"
                color={"primary"}
              >
                { downloading &&
                  <CircularProgress
                    size={12}
                    thickness={7}
                    color="inherit"
                    style={{ marginRight: "6px" }}
                  />
                }

                Pobierz PDF
              </Button>
            </div>
          </ExamPageCard>
        )}
    </AdminPageCard>
  )
}

export default ExamResultPage