import {gql} from "@apollo/client"

export const ADD_ADDITIONAL_INFORMATION = gql`
    mutation addAdditionalInformation($input: AddAdditionalInformationInput!) {
      addAdditionalInformation(input: $input){
        clientMutationId
        answer {
          additionalInformation
        }
      }
    }
`