import {gql} from "@apollo/client"

export const ADD_ANSWER_DESCRIPTION = gql`
    mutation addAnswerDescription($userId: UUID!, $category: String!, $description: String!, $employee: Boolean!) {
      addAnswerDescription(input: {userId: $userId, category: $category, description: $description, employee: $employee}){
        clientMutationId
        answer {
          description
        }
      }
    }
`