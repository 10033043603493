import {FC, useEffect, useState} from 'react'
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component";
import {Box} from "@material-ui/core"
import {useMutation} from "@apollo/client";
import {ADD_ADDITIONAL_INFORMATION} from "./AdditionalAnswerDescription.mutation";
import {useFormContext} from "react-hook-form";
import {addSavingAnswer, removeSavingAnswer} from "../../../store/savingExamAnswer/savingExamAnswer.slice";
import {useDispatch} from "react-redux";
import { useDebouncedCallback } from 'use-debounce';

interface AdditionalAnswerDescriptionProps {
  code: string;
  userId: string;
}

const AdditionalAnswerDescription: FC<AdditionalAnswerDescriptionProps> = (
  {
    userId,
    code,
  }
) => {
  const form = useFormContext()
  const dispatch = useDispatch()
  const [isSaved, setIsSaved] = useState<boolean>(true)

  const [addAdditionalInformation, {loading}] = useMutation(ADD_ADDITIONAL_INFORMATION)

  const formFieldName = `additionalInformation[${code}]`

  useEffect(() => {
    if(loading) {
      dispatch(addSavingAnswer(formFieldName))
    } else {
      setIsSaved(true)
      dispatch(removeSavingAnswer(formFieldName))
    }
  },[loading])

  const save = async () => {
    if (isSaved) return;
    const value = form.getValues(formFieldName);

    try {
      await addAdditionalInformation({
        variables: {
          input: {
            userId,
            category: code,
            additionalInformation: value.trimStart(),
          }
        }
      })
    }
    catch (e: any) {
      console.error(e)
    }
  }

  const debouncedSave = useDebouncedCallback(() => {
    save()
  }, 1000)

  const onChange = () => {
    setIsSaved(false)
    dispatch(addSavingAnswer(formFieldName))

    debouncedSave()
  }

  return (
    <Box mt={6}>
      <TextFieldController
        name={formFieldName}
        label={"Informacja dodatkowa"}
        multiline
        maxCharacters={1000}
        onBlur={save}
        save={onChange}
      />
    </Box>
  )
}

export default AdditionalAnswerDescription