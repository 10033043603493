import React, {FC, useEffect} from "react"
import {Box, Grid} from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import {FormProvider, useForm} from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import {ExaminedPersonUserItemNode, UserItemNode} from "../../../store/user/user.types"
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component"
import {UserRole} from "../userRole/UserRole.types"
import TestedPersonOccupationSelect
  from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.component";
import {useSelector} from "react-redux";
import {selectUserRole} from "../../../store/user/user.selectors";
import TestedPersonsWorkplaceThreatsSelect
  from "../../testedPersons/testedPersonsWorkplaceThreatsSelect/TestedPersonsWorkplaceThreatsSelect.component";
import {GridSize} from "@material-ui/core/Grid/Grid";
import AgeCategoryController from "../addUserPopup/ageCategoryController/AgeCategoryController";
import GenderController from "../addUserPopup/genderController/GenderController.component";
import MotivationController from "../addUserPopup/motivationController/MotivationController.component";
import SeniorityController from "../addUserPopup/seniorityController/SeniorityController.component";
import EducationController from "../addUserPopup/educationController/EducationController.component";
import WorkTypeController from "../addUserPopup/workTypeController/WorkTypeController.component";

interface PreviewUserPopupProps {
  user: UserItemNode;
  open: boolean;
  onClose(): void;
}

const PreviewUserPopup: FC<PreviewUserPopupProps> = ({user, open, onClose}) => {
  const isTenantFieldRequired = user.userRole && user.userRole !== UserRole.ADMIN

  const gridSize = {
    xs: 12 as GridSize,
    sm: 6 as GridSize,
    lg: 4 as GridSize,
  }

  const form = useForm({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      fullName: user.fullName,
      email: user.email,
      occupation: (user as ExaminedPersonUserItemNode)?.occupationType?.occupationTypeId,
      workplaceThreats: (user as ExaminedPersonUserItemNode)?.workplaceThreats,
      tenantId: user.tenantsList?.map(tenant => tenant.tenantId),
      birthYear: (user as ExaminedPersonUserItemNode)?.birthYear,
      age: (user as ExaminedPersonUserItemNode)?.age ?? "",
      ageCategoryId: (user as ExaminedPersonUserItemNode)?.ageCategoryId ?? "",
      genderId: (user as ExaminedPersonUserItemNode)?.genderId ?? "",
      motivation: (user as ExaminedPersonUserItemNode)?.motivation ?? "",
      seniorityId: (user as ExaminedPersonUserItemNode)?.seniorityId ?? "",
      educationId: (user as ExaminedPersonUserItemNode)?.educationId ?? "",
      weigh: (user as ExaminedPersonUserItemNode)?.weigh ?? "",
      height: (user as ExaminedPersonUserItemNode)?.height ?? "",
      bmi: (user as ExaminedPersonUserItemNode)?.bmi ?? "",
      workTypeId: (user as ExaminedPersonUserItemNode)?.workTypeId ?? "",
    }
  })

  useEffect(() => {
    if (open) {
      form.setValue("fullName", user.fullName)
      form.setValue("occupation", (user as ExaminedPersonUserItemNode)?.occupationType?.occupationTypeId)
      form.setValue("workplaceThreats", (user as ExaminedPersonUserItemNode)?.workplaceThreats)

      if (user.userRole === UserRole.EXAMINED_PERSON) {
        form.setValue("birthYear", (user as ExaminedPersonUserItemNode)?.birthYear ?? "")
        form.setValue("age", (user as ExaminedPersonUserItemNode)?.age ?? "")
        form.setValue("ageCategoryId", (user as ExaminedPersonUserItemNode)?.ageCategoryId ?? "")
        form.setValue("genderId", (user as ExaminedPersonUserItemNode)?.genderId ?? "")
        form.setValue("motivation", (user as ExaminedPersonUserItemNode)?.motivation ?? "")
        form.setValue("seniorityId", (user as ExaminedPersonUserItemNode)?.seniorityId ?? "")
        form.setValue("educationId", (user as ExaminedPersonUserItemNode)?.educationId ?? "")
        form.setValue("weigh", (user as ExaminedPersonUserItemNode)?.weigh ?? "")
        form.setValue("height", (user as ExaminedPersonUserItemNode)?.height ?? "")
        form.setValue("bmi", (user as ExaminedPersonUserItemNode)?.bmi ?? "")
        form.setValue("workTypeId", (user as ExaminedPersonUserItemNode)?.workTypeId ?? "")
      }
   }
  }, [open])

  return (
    <Popup
      open={open}
      onClose={onClose}
      title="Podgląd osoby badanej"
      desktopWidth={"100%"}
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item {...gridSize}>
                <TextFieldController
                  name="fullName"
                  label={'Imię i nazwisko'}
                  isRequired
                  mb
                  disabled={true}
                />
              </Grid>

              <Grid item {...gridSize}>
                <TextFieldController
                  name="email"
                  label={'E-mail'}
                  isRequired
                  mb
                  disabled={true}
                />
              </Grid>

              {user.userRole === UserRole.EXAMINED_PERSON &&
                <Grid item {...gridSize}>
                  <TestedPersonOccupationSelect
                    name="occupation"
                    label={'Typ zawodu'}
                    isRequired
                    mb
                    disabled
                  />
                </Grid>
              }

              { user.userRole === UserRole.EXAMINED_PERSON &&
                <Grid item sm={12} lg={8}>
                  <TestedPersonsWorkplaceThreatsSelect
                    name={'workplaceThreats'}
                    label={"Zagrożenia na stanowisku pracy"}
                    disabled
                    mb
                  />
                </Grid>
              }

              <Grid item {...gridSize}>
                <TenantsSelect
                  name="tenantId"
                  label={'Podmiot'}
                  isRequired={isTenantFieldRequired}
                  mb
                  disabled
                />
              </Grid>

              { user.userRole === UserRole.EXAMINED_PERSON && (
                <>
                  <Grid item {...gridSize}>
                    <TextFieldController
                      name="birthYear"
                      label={'Rok urodzenia'}
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name="age"
                      label={'Wiek'}
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <AgeCategoryController
                      name='ageCategoryId'
                      label='Kategoria wiekowa'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <GenderController
                      name='genderId'
                      label='Płeć'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <MotivationController
                      name='motivation'
                      label='Poziom motywacji do uczestnictwa'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <SeniorityController
                      name='seniorityId'
                      label='Staż pracy w firmie'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <EducationController
                      name='educationId'
                      label='Wykształcenie'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name='weigh'
                      label='Masa ciała (kg)'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name='height'
                      label='Wysokość (cm)'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <TextFieldController
                      name='bmi'
                      label='BMI'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>

                  <Grid item {...gridSize}>
                    <WorkTypeController
                      name='workTypeId'
                      label='Rodzaj pracy'
                      isRequired
                      mb
                      disabled
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Popup>
  )
}

export default PreviewUserPopup
