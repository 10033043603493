import {FC} from 'react'
import {Box, Paper, Typography} from "@material-ui/core";
import {useExamAnswerStyles} from "./ExamAnswer.styles";
import {Occupations} from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.types";
import clsx from "clsx";
import {employerRecommendations} from "../examUtils/recommendationsForEmpolyer.utils";
import {
  WorkplaceThreatsTypes
} from "../../testedPersons/testedPersonsWorkplaceThreatsSelect/TestedPersonsWorkplaceThreatsSelect.types";
import removeOrphans from "../../../utils/removeOrphans";
import {ColorLight, QuestionType} from "../examUtils/exam.types";
import {ExamResultTypes} from "../../testedPersons/examsResults/ExamsResult.types";
import {employeeRecommendations} from "../examUtils/recommendationsForEmployee.utils";
import BoxWithDivider from "../../common/boxWithDivider/BoxWithDivider.component";
import AdditionalAnswerResultDescription from "../additionalAnswerResultDescription/AdditionalAnswerResultDescription.component";
import {getAnswerColor, getLabelForColor} from "../examUtils/answerColor.utils";

interface ExamAnswerProps extends QuestionType {
  userAnswers: Array<{
    category: string,
    answers: string[],
    description?: string
    employeeDescription?: string
  }>
  userOccupationType: Occupations,
  userThreats?: WorkplaceThreatsTypes[]
  examResultType: ExamResultTypes;
  userId: string;
  refetch: () => void
}

const ExamAnswer: FC<ExamAnswerProps> = (
  {
    code,
    title,
    userOccupationType,
    userAnswers,
    crucialForOccupations,
    userThreats,
    examResultType,
    userId,
    refetch
  }) => {
  const classes = useExamAnswerStyles()

  const answersObj = userAnswers.find(answer => answer.category === code)
  const isCrucial = crucialForOccupations.includes(userOccupationType)

  const colorsArr: ColorLight[] = (answersObj && answersObj.answers)
    ? answersObj.answers
      .filter(answer => answer.charAt(0) !== "+")
      .map(answer => getAnswerColor(answer, isCrucial, answersObj.category.charAt(0) === "E"))
      .filter(item => item) as ColorLight[]
    : []

  const employerRecommendationObj = employerRecommendations.find(item => item.code === code)
  const currentUserRecommendations = employerRecommendationObj?.recommendationsList.filter(item => userThreats?.includes(item.threat))

  const employeeRecommendationObj = employeeRecommendations.find(item => item.code === code)
  const additionalDescription = examResultType === ExamResultTypes.GUARDIAN_RESULT ? answersObj?.description : answersObj?.employeeDescription

  return (
    <Paper
      elevation={4}
      className={clsx(classes.root, "print-exam-answer-box")}
    >
      <Typography
        variant={"h5"}
        component={"span"}
      >
        {code}
      </Typography>

      <Typography variant={'body1'} className={classes.sectionTitle}>
        {title}
      </Typography>

      <BoxWithDivider title={"Wynik"} className={classes.sectionTitle}>
        { answersObj?.answers
          .filter(answer => answer.charAt(0) !== "+")
          .map((answer, index) => {
          const answerColor = colorsArr[index]
          return (
            <Box className={classes.colorWrapper} key={index}>
              <div className={
                clsx(classes.circle,
                  answerColor === ColorLight.RED && classes.red,
                  answerColor === ColorLight.YELLOW && classes.yellow,
                  answerColor === ColorLight.GREEN && classes.green
                )}
              />
              <Typography variant={"body1"}>
                {getLabelForColor(answerColor, examResultType)}
                {answer === "8" && "Nieokreślone"}
                {answer === "9" && "Nie dotyczy"}
              </Typography>
            </Box>
          )
        })}
      </BoxWithDivider>

      <div className={classes.wrapper}>

        { (currentUserRecommendations
            && currentUserRecommendations?.length > 0
            && (colorsArr.includes(ColorLight.RED) || colorsArr.includes(ColorLight.YELLOW))
            && examResultType === ExamResultTypes.GUARDIAN_RESULT
          ) &&
          <BoxWithDivider title={"Rekomendacje dla pracodawcy:"} className={classes.sectionTitle}>
            <ul>
              {currentUserRecommendations?.map(({recommendation}) => (
                <li key={recommendation}>
                  <Typography
                    variant={"body2"}
                    component={"p"}
                    dangerouslySetInnerHTML={{__html: removeOrphans(recommendation)}}
                  />
                </li>
              ))}
            </ul>
          </BoxWithDivider>
        }

        { (examResultType === ExamResultTypes.EXAMINED_PERSON_RESULTS
            && colorsArr[0] !== undefined
            && employeeRecommendationObj?.colors[colorsArr[0]]  !== undefined
          ) &&
          <BoxWithDivider title={"Rekomendacje dla pracownika:"} className={classes.sectionTitle}>
            <Typography
              variant={"body2"}
              component={"p"}
              dangerouslySetInnerHTML={{__html: removeOrphans(employeeRecommendationObj?.colors[colorsArr[0]] )}}
            />
          </BoxWithDivider>
        }

        <AdditionalAnswerResultDescription
          description={additionalDescription}
          code={code}
          userId={userId}
          refetch={refetch}
          examResultType={examResultType}
        />
      </div>
    </Paper>
  )
}

export default ExamAnswer