import {FC} from 'react'
import {Alert} from "@material-ui/lab";
import {Occupations} from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.types";
import {AnswerListItem, ColorLight} from "../examUtils/exam.types";
import {EXAM_QUESTIONS} from "../examUtils/examQuestions.utils";
import {getAnswerColor} from "../examUtils/answerColor.utils";
import {getGeneralDescription, getAlertSeverity} from "./ExamResultGeneralDescription.utils";
import {Box, Typography} from "@material-ui/core";
import {useExamResultGeneralDescriptionStyles} from "./ExamResultGeneralDescription.styles";
import clsx from "clsx";

interface ExamResultGeneralDescriptionProps {
  userAnswers: AnswerListItem[];
  userOccupationType: Occupations;
}

const ExamResultGeneralDescription: FC<ExamResultGeneralDescriptionProps> = (
  {
    userAnswers,
    userOccupationType
  }) => {
  const classes = useExamResultGeneralDescriptionStyles();

  const answersColors = userAnswers.reduce((arr: ColorLight[], currentAnswer: AnswerListItem) => {
    const examItem = EXAM_QUESTIONS.find(item => item.code === currentAnswer.category)
    if (!examItem) return arr

    const isCrucial = examItem.crucialForOccupations.includes(userOccupationType)
    const currentAnswersColors = currentAnswer.answers
      .map((answer: string) => getAnswerColor(answer, isCrucial, examItem.code.charAt(0) === "E"))
      .filter(item => !!item) as ColorLight[]

    return [...arr, ...currentAnswersColors]
  }, [])

  const getGeneralColor = (): ColorLight => {
    if(answersColors.includes(ColorLight.RED)) {
      return ColorLight.RED
    } else if(answersColors.includes(ColorLight.YELLOW)) {
      return ColorLight.YELLOW
    } else {
      return ColorLight.GREEN
    }
  }

  const generalColor = getGeneralColor()

	return (
    <Box marginY={5}>
      <Typography
        variant={"h5"}
        component={"span"}
        className={clsx(classes.title, "print-no-margin")}
      >
        Ogólne podsumownanie badania
      </Typography>

      <Alert severity={getAlertSeverity(generalColor)}>
        <Typography
          variant={"body1"}
          component={"div"}
          align={"justify"}
          className={"print-text-normal"}
        >
          { getGeneralDescription(generalColor) }
        </Typography>
      </Alert>
    </Box>
  )
}

export default ExamResultGeneralDescription