import {FC} from 'react'
import {Typography} from "@material-ui/core";
import Popup from "../../common/popup/Popup.component";

interface FinishExamModalProps {
  open: boolean
}

const FinishedExamModal:FC<FinishExamModalProps> = ({open}) => {
  return (
      <Popup open={open} title={ "Badanie zakończone"} >
          <Typography variant={"body1"} component={"p"}>
            Badanie zostało zakończone, dziękujemy za udział!
          </Typography>
      </Popup>
  )
}

export default FinishedExamModal